import React from 'react';
import {useTranslation} from "react-i18next";
import useMediaQuery from "../../../hooks/useMediaQuery";
import Dialog from "../Dialog/Dialog";
import cl from './ConfirmationDialog.module.css';
import Button, {ButtonType} from "../Button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";

export enum ConfirmationDialogType {
    Alert = 0,
    Warning,
    Information
}

type ConfirmationDialogProps = {
    type: ConfirmationDialogType;
    open: boolean;
    title: string;
    text: string;
    primaryButtonText?: string;
    secondaryButtonText?: string;
    onCancel: () => void;
    onSubmit: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
                                                                   type,
                                                                   open,
                                                                   title,
                                                                   text,
                                                                   primaryButtonText,
                                                                   secondaryButtonText,
                                                                   onCancel,
                                                                   onSubmit
                                                               }) => {
    const {t} = useTranslation();
    const is650MaxWidth = useMediaQuery('(max-width: 650px)');

    if (!open) {
        return null;
    }

    return (
        <Dialog open={open}
                width={is650MaxWidth ? '450px' : '550px'}>
            <main className={cl.container} data-type={ConfirmationDialogType[type].toString().toLowerCase()}>
                <section className={cl.contentRow}>
                    <div>
                        {type === ConfirmationDialogType.Alert
                            ? <FontAwesomeIcon className={cl.icon} icon={faTriangleExclamation}/>
                            : <FontAwesomeIcon className={cl.icon} icon={faCircleExclamation}/>
                        }
                    </div>
                    <div>
                        <h1>
                            {title}
                        </h1>
                        <p>
                            {text}
                        </p>
                    </div>
                </section>
                <footer className={cl.buttonsRow}>
                    <Button buttonType={ButtonType.Primary}
                            onClick={onCancel}
                            style={{
                                fontWeight: '500',
                                width: '30%',
                                backgroundColor: 'transparent',
                                border: '1px solid rgb(0,111,230)',
                                color: 'black',
                                padding: '7px'
                            }}>
                        {!secondaryButtonText ? t("btn.cancel") : secondaryButtonText}
                    </Button>
                    <Button buttonType={ButtonType.Error}
                            onClick={onSubmit}
                            style={{
                                fontWeight: '500',
                                width: '30%',
                                padding: '7px',
                                border: '1px solid red'
                            }}>
                        {!primaryButtonText ? t("btn.confirm") : primaryButtonText}
                    </Button>
                </footer>
            </main>
        </Dialog>
    );
};

export default ConfirmationDialog;