import React from 'react';
import cl from './LoginInput.module.css';
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type LoginInputProps = {
    disabled: boolean;
    icon: IconProp;
    error?: string;
} & React.HTMLProps<HTMLInputElement>;

const LoginInput: React.FC<LoginInputProps> = ({
                                                   icon,
                                                   error,
                                                   ...rest
                                               }) => {
    return (
        <div className={cl.container}>
            <div className={cl.inputContainer}>
                <input className={cl.input} {...rest}/>
                <span>
                    <FontAwesomeIcon icon={icon}/>
                </span>
            </div>
            {error
                ? <div className={cl.errorContainer}><div className='server-error-label'>{error}</div></div>
                : null
            }
        </div>
    );
};

export default LoginInput;