import {RootState} from "../../app/store/store";
import {createSelector} from "@reduxjs/toolkit";
import {IRascoApplicationState} from "../../app/types/redux/IRascoApplicationState";

export const selectCompanyBranchItem = (state: RootState) => state.rascoApplication.companyBranchItem;

export const selectRascoApplication = createSelector(
    (state: RootState): IRascoApplicationState => state.rascoApplication,
    (applicationState: IRascoApplicationState): IRascoApplicationState => ({
        confirmation: applicationState.confirmation,
        email: applicationState.email,
        orderDate: applicationState.orderDate,
        employeeName: applicationState.employeeName,
        truckLicencePlate: applicationState.truckLicencePlate,
        trailerLicencePlate: applicationState.trailerLicencePlate,
        doubleTrailerLicencePlate: applicationState.doubleTrailerLicencePlate,
        arrivalDate: applicationState.arrivalDate,
        arrivalTime: applicationState.arrivalTime,
        loadingDate: applicationState.loadingDate,
        loadingTime: applicationState.loadingTime,
        sealNumber: applicationState.sealNumber,
        city: applicationState.city,
        orderItems: applicationState.orderItems,
        companyBranchItemId: applicationState.companyBranchItemId,
        utcOffset: applicationState.utcOffset,
        id: applicationState.id,
        transportType: applicationState.transportType
    } as IRascoApplicationState)
);