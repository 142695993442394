import React from 'react';

const LoadingScreen: React.FC = () => {
    return (
        <div className="loading-screen-container">
            <div className="loader">
                <div className="top-polygon top-polygon-animation"></div>
                <div className="side-polygon left-polygon left-polygon-animation"></div>
                <div className="side-polygon right-polygon right-polygon-animation"></div>
            </div>
        </div>
    );
};

export default LoadingScreen;