import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../app/store";
import {selectAuthUser} from "../../../features/authentication/authenticationSelectors";
import LoadingScreen from "../../ui/LoadingScreen/LoadingScreen";
import {Outlet} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getCurrentUser} from "../../../features/authentication/authenticationSlice";
import {Language} from "../../../app/types/enums/Language";
import cl from './AuthorizedLayout.module.css';
import Header from "../../ui/Header/Header";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";

const AuthorizedLayout: React.FC = () => {
    const user = useAppSelector(selectAuthUser);
    const dispatch = useAppDispatch();
    const {i18n} = useTranslation();

    useEffect(() => {
        dispatch(getCurrentUser());
    }, [dispatch]);

    useEffect(() => {
        if (user && user.language !== undefined && user.language !== null) {
            i18n.changeLanguage(Language[user.language].toString().toLowerCase()).then(() => {
                //ignore
            });
        }
    }, [user, i18n])

    if (!user) {
        return <LoadingScreen/>;
    }

    return (
        <div className={cl.container}>
            <ToastContainer position="top-right"
                            autoClose={5000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick={false}
                            rtl={false}
                            pauseOnFocusLoss={false}
                            draggable={false}
                            pauseOnHover
                            bodyClassName='toast-body-class'
                            theme="light"/>
            <Header/>
            <main>
                <Outlet/>
            </main>
        </div>
    );
};

export default AuthorizedLayout;