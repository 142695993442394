import {PartialBy} from "../app/Partial";

export type ButtonAlign = 'left' | 'right';

export interface IRascoApplicationWizardState {
    cancelButtonAlign: ButtonAlign;
    nextButtonAlign: ButtonAlign;
    previousButtonAlign: ButtonAlign;
    showCancelButton: boolean;
    showNextButton: boolean;
    showPreviousButton: boolean;
    isCancelButtonDisabled: boolean;
    isNextButtonDisabled: boolean;
    isPreviousButtonDisabled: boolean;
    showProgressBar: boolean;
    progressBarItems?: Array<IWizardProgressBarStep>;
    showFooter: boolean;
}

export interface IWizardProgressBarStep {
    status: WizardProgressBarStatus;
    label: string;
    orderStep: number;
    description?: string;
}

export type UpdateWizardProgressBarStep = PartialBy<IWizardProgressBarStep, 'label'>;

export enum WizardProgressBarStatus {
    UnComplete = 0,
    Complete,
    Progress
}