import React from 'react';
import {useTranslation} from "react-i18next";
import {OrderStatus} from "../../../../../app/types/enums/OrderStatus";
import cl from './OrderStatusTemplate.module.css';

const OrderStatusTemplate = (props: any) => {
    const {t} = useTranslation();

    if (props['status'] === null || props['status'] === undefined) {
        return null;
    }

    return (
        <div className={cl.container} data-status={OrderStatus[props.status].toString().toLowerCase()}>
            <div>
                {t(`order.order-status.${OrderStatus[props.status].toString().toLowerCase()}`)}
            </div>
        </div>
    );
};

export default OrderStatusTemplate;