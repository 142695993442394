import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IAuthenticationState} from "../../app/types/redux/IAuthenticationState";
import apiAxios from "../../app/api/apiAxios";
import {IUser} from "../../app/types/models/IUser";
import {IResponseModel, isResponseModel} from "../../app/types/models/IResponseModel";
import {ResponseResultCode} from "../../app/types/enums/ResponseResultCode";
import {AxiosResponse} from "axios";
import i18n from "i18next";

const initialState: IAuthenticationState = {
    isAuth: false,
    isLoading: true,
    user: null
};

export const validateIsUserAuth = createAsyncThunk<boolean, undefined, { rejectValue: boolean }>(
    "authentication/validateIsUserAuth",
    async (_, thunkAPI) => {
        try {
            const response = await apiAxios.get('api/authentication/isAuth');

            if (response.status === 200 || response.status === 204) {
                return true;
            }

        } catch (error) {
            return thunkAPI.rejectWithValue(false);
        }

        return false;
    }
);

export const getCurrentUser = createAsyncThunk<IUser, undefined, { rejectValue: IResponseModel }>(
    "application/getCurrentUser",
    async (_, thunkAPI) => {
        try {
            const response = await apiAxios.get<IUser | IResponseModel>('api/authentication/getCurrentUser');

            if (response?.data !== null) {
                if (isResponseModel(response.data)) {
                    if (response.data.resultCode === ResponseResultCode.Forbidden ||
                        response.data.resultCode === ResponseResultCode.NotFound ||
                        response.data.resultCode === ResponseResultCode.ServerError) {
                        thunkAPI.dispatch(logOut());
                    }
                } else {
                    return response.data;
                }
            }
        } catch (error) {
            //ignore
        }

        return thunkAPI.rejectWithValue({
            resultCode: ResponseResultCode.ServerError
        } as IResponseModel)
    }
);

export const logOut = createAsyncThunk(
    "application/logOut",
    async () => {
        try {
            const response: AxiosResponse = await apiAxios.post('api/authentication/logout');

            if (response.status === 200 || response.status === 204) {
                i18n.changeLanguage('en').then(() => {
                    return true;
                });
            }

        } catch (error) {
            return false;
        }

        return false;
    }
);


const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setIsAuth: (state, action: PayloadAction<boolean>) => {
            state.isAuth = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(validateIsUserAuth.pending, (state) => {
            state.isLoading = true;
            state.isAuth = false;
        });
        builder.addCase(validateIsUserAuth.fulfilled, (state, {payload}) => {
            state.isAuth = payload;
            state.isLoading = false;
        });
        builder.addCase(validateIsUserAuth.rejected, (state, {payload}) => {
            if (payload)
                state.isAuth = payload;

            state.isLoading = false;
        });

        builder.addCase(getCurrentUser.fulfilled, (state, {payload}) => {
            state.user = payload;
        });
        builder.addCase(getCurrentUser.rejected, (state) => {
            state.isAuth = false;
        });
        builder.addCase(getCurrentUser.pending, (state) => {
            state.user = null;
        });

        builder.addCase(logOut.fulfilled, (state) => {
            state.isAuth = false;
        });
    }
});

export const {setIsAuth} = authenticationSlice.actions;

export default authenticationSlice.reducer;