import React, {useEffect, useState} from 'react';
import {Query} from "@syncfusion/ej2-data";
import SyncfusionComboBox from "../../../../../../../components/ui/SyncfusionComboBox/SyncfusionComboBox";
import {useTranslation} from "react-i18next";
import {IRascoApplicationOrderItem} from "../../../../../../../app/types/redux/IRascoApplicationState";
import {updateOrderItem} from "../../../../../../../features/rascoApplication/rascoApplicationSlice";
import {useAppDispatch} from "../../../../../../../app/store";

type OrderItemContentSelectorProps = {
    orderItem: IRascoApplicationOrderItem;
};

const OrderItemContentSelector: React.FC<OrderItemContentSelectorProps> = ({
                                                                               orderItem
                                                                           }) => {
    const dispatch = useAppDispatch();
    const [query, setQuery] = useState<Query | null>(null);
    const {t} = useTranslation();

    useEffect(() => {
        if (orderItem && orderItem.orderItemContentTypeId !== null &&
            orderItem.orderItemContentTypeId !== undefined) {
            if (orderItem.orderItemContentTypeId > 0) {
                setQuery(new Query().addParams('orderItemContentTypeId', orderItem.orderItemContentTypeId.toString()));
            } else if (orderItem.orderItemContentTypeId === -1 &&
                orderItem.content !== null &&
                orderItem.content !== undefined &&
                orderItem.content.length > 0) {
                setQuery(new Query().addParams('orderItemContentTypeId', '-1').addParams('name', orderItem.content));
            }
        } else {
            setQuery(new Query().addParams('$top', '30'));
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!query) {
        return null
    }

    return (
        <SyncfusionComboBox url={'/api/orderItemContentType/getOrderItemContentTypes'}
                            id={`order-item-content-type-${orderItem.position}`}
                            enabled={true}
                            query={query}
                            placeholder={t("application.cargo-wizard.content")}
                            value={orderItem?.orderItemContentTypeId}
                            allowFiltering={true}
                            allowCustom={true}
                            showClearButton={true}
                            fields={{value: 'id', text: 'name'}}
                            select={(ev) => {
                                if (ev.itemData !== null && ev.itemData !== undefined) {
                                    let data = ev.itemData as { id: number | string, name: string };
                                    if (data && data.id !== undefined && data.name !== undefined && data.id !== null && data.name !== null) {
                                        dispatch(updateOrderItem({
                                            ...orderItem,
                                            orderItemContentTypeId: +data.id,
                                            content: data.name,
                                            updateField: 'content'
                                        }));
                                    }
                                }
                            }}
                            change={(ev) => {
                                if (ev.itemData !== null && ev.itemData !== undefined) {
                                    let data = ev.itemData as { id: number | string, name: string };
                                    if (data.id !== null && data.name !== null &&
                                        orderItem?.orderItemContentTypeId !== data.id && orderItem?.content !== data.name) {
                                        dispatch(updateOrderItem({
                                            ...orderItem,
                                            orderItemContentTypeId: +data.id,
                                            content: data.name,
                                            updateField: 'content'
                                        }));
                                    }
                                }

                                if ((ev.itemData === undefined || ev.itemData === null) &&
                                    ev.previousItemData !== undefined && ev.previousItemData !== null
                                    && orderItem?.orderItemContentTypeId !== undefined) {
                                    dispatch(updateOrderItem({
                                        ...orderItem,
                                        orderItemContentTypeId: undefined,
                                        content: undefined,
                                        updateField: 'content'
                                    }));
                                }
                            }}
                            filtering={(ev) => {
                                setQuery(() => {
                                    if (ev.text === null || ev.text === undefined || ev.text.length < 2) {
                                        return new Query().addParams('$top', '0');
                                    } else {
                                        return new Query().addParams("term", ev.text);
                                    }
                                });
                            }}
                            custom={(ev) => {
                                if (ev && ev.text) {
                                    ev.item = {
                                        name: ev.text.toUpperCase(),
                                        id: -1
                                    } as { name: string, id: number };

                                    dispatch(updateOrderItem({
                                        ...orderItem,
                                        orderItemContentTypeId: -1,
                                        content: ev.text.toUpperCase(),
                                        updateField: 'content'
                                    }));
                                }
                            }}/>
    );
};

export default OrderItemContentSelector;