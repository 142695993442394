import React, {useState} from 'react';
import cl from './OrderCsdZipDownloadButtonTemplate.module.css';
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {OrderStatus} from "../../../../../app/types/enums/OrderStatus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileZipper} from "@fortawesome/free-solid-svg-icons";
import DownloadOrderCsdZipFileDialog from "../../actions/DownloadOrderCsdZipFile/DownloadOrderCsdZipFileDialog";
import {createPortal} from "react-dom";

type OrderCsdZipDownloadButtonTemplateProps = {
    data: any;
};

const OrderCsdZipDownloadButtonTemplate: React.FC<OrderCsdZipDownloadButtonTemplateProps> = ({
                                                                                                 data
                                                                                             }) => {
    const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);

    return (
        <>
            {createPortal(
                <DownloadOrderCsdZipFileDialog opened={isDialogOpened}
                                               setOpened={setIsDialogOpened}
                                               order={{
                                                   id: data['id'],
                                                   lagerNumber: data['lagerNumber']
                                               }}/>,
                document.body
            )}

            <div>
                <Button buttonType={ButtonType.Action}
                        disabled={(data['status'] === null || data['status'] === undefined || data.status !== OrderStatus.Finished)}
                        style={{padding: '0', width: '40px', height: '30px'}}
                        onClick={async (ev) => {
                            ev.stopPropagation();

                            if ((data['status'] === null || data['status'] === undefined || data.status !== OrderStatus.Finished)) {
                                return;
                            }

                            setIsDialogOpened(prev => !prev);
                        }}>
                    <div className={cl.iconButtonContent}>
                        <span><FontAwesomeIcon icon={faFileZipper}/></span>
                    </div>
                </Button>
            </div>
        </>
    );
};

export default OrderCsdZipDownloadButtonTemplate;