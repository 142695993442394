import React, {useEffect, useState} from 'react';
import cl from './SubmitWizard.module.css';
import {IRascoApplicationWizardState} from "../../../../../app/types/redux/IRascoApplicationWizardState";
import {useAppDispatch, useAppSelector} from "../../../../../app/store";
import {
    setRascoApplicationWizardState
} from "../../../../../features/rascoApplicationWizard/rascoApplicationWizardSlice";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import useDownloadFile from "../../../../../hooks/useDownloadFile";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {clear} from "../../../../../features/rascoApplication/rascoApplicationSlice";
import {useNavigate} from "react-router-dom";
import {selectRascoApplication} from "../../../../../features/rascoApplication/rascoApplicationSelectors";
import apiAxios from "../../../../../app/api/apiAxios";
import {IResponseModel} from "../../../../../app/types/models/IResponseModel";
import Logo from "../../../../../components/ui/Logo/Logo";
import {
    setHeaderMenuDisabledState,
    setHeaderMenuLabel,
    setHeaderMenuShownState
} from "../../../../../features/application/applicationSlice";
import {ResponseResultCode} from "../../../../../app/types/enums/ResponseResultCode";
import {AxiosError} from "axios";
import {toast} from "react-toastify";
import useWizard from "../../../../../components/ui/Wizard/utils/useWizard";
import getServerErrorResourceName from "../../../../../app/apiError";

const footerInitialState: IRascoApplicationWizardState = {
    isPreviousButtonDisabled: true,
    showPreviousButton: false,
    isNextButtonDisabled: true,
    showNextButton: false,
    isCancelButtonDisabled: true,
    showCancelButton: false,
    showProgressBar: false,
    showFooter: false,
    cancelButtonAlign: 'left',
    nextButtonAlign: 'right',
    previousButtonAlign: 'right'
}

const SubmitWizard: React.FC = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingLabel, setLoadingLabel] = useState<string | undefined>();
    const navigate = useNavigate();
    const application = useAppSelector(selectRascoApplication);
    const [savedOrderId, setSavedOrderId] = useState<number | undefined>();
    const [rascoApplicationNumber, setRascoApplicationNumber] = useState<string | undefined>();
    const {goToStep} = useWizard();

    useEffect(() => {
        if (application) {
            (async () => {
                setIsLoading(prev => !prev);
                setLoadingLabel(t("application.submit-wizard.loading-label"));

                try {
                    const response = await apiAxios.post<IResponseModel>('/api/rascoApplication/save', application);
                    if (response?.data !== null &&
                        response?.data.resultCode === ResponseResultCode.Ok &&
                        response?.data.description) {
                        setSavedOrderId(Number(response.data.description));
                        setRascoApplicationNumber(response?.data.field);
                    }
                } catch (error) {
                    if (error instanceof AxiosError && error.response !== undefined && error.response.data !== null) {
                        if (error.response.data.resultCode === ResponseResultCode.NotFound &&
                            error.response.data.description === 'customer') {
                            toast.error<string>(t("application.submit-wizard.errors.customer-not-found"));

                            dispatch(setHeaderMenuDisabledState(false));
                            dispatch(setHeaderMenuShownState(true));

                            dispatch(clear());

                            navigate('/order/application');
                        } else if (error.response.data.resultCode === ResponseResultCode.NotValidData &&
                            error.response.data.description === 'driverApplicationBuild') {
                            toast.error<string>(t("application.submit-wizard.errors.driver-application-build"));

                            goToStep(0);
                        } else if (error.response.data.resultCode === ResponseResultCode.NotValidData &&
                            error.response.data.description === 'orderBuild') {
                            toast.error<string>(t("application.submit-wizard.errors.order-build"));

                            goToStep(0);
                        } else if (error.response.data.resultCode === ResponseResultCode.NotValidData &&
                            error.response.data.description === 'orderItems') {
                            toast.error<string>(t("application.submit-wizard.errors.order-items"));

                            goToStep(0);
                        } else if (error.response.data.resultCode === ResponseResultCode.ServerError) {
                            toast.error<string>(t("application.submit-wizard.errors.server-error"));

                            dispatch(setHeaderMenuDisabledState(false));
                            dispatch(setHeaderMenuShownState(true));

                            dispatch(clear());

                            navigate('/order/application');
                        } else if (error.response.data.resultCode === ResponseResultCode.Unauthorized) {
                            toast.error<string>(t("application.submit-wizard.errors.unauthorized"));

                            dispatch(setHeaderMenuDisabledState(false));
                            dispatch(setHeaderMenuShownState(true));

                            dispatch(clear());

                            navigate('/order/application');
                        } else {
                            if (error.response.data.description !== null && error.response.data.description !== undefined) {
                                toast.error<string>(t(getServerErrorResourceName('rascoApplication', error.response.data.resultCode, error.response.data.field, error.response.data.description)))
                            } else {
                                toast.error<string>(t(getServerErrorResourceName('rascoApplication', error.response.data.resultCode, error.response.data.field)))
                            }
                        }
                    } else if (error instanceof Error) {
                        toast.error<string>(`${t("shared.errors.server-error")} (${error.message})`);
                    }
                }

                setIsLoading(prev => !prev);
            })();
        }

        dispatch(setHeaderMenuLabel(''));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(setRascoApplicationWizardState(footerInitialState));
    }, [dispatch]);

    const getFileUrl = (): string => `/api/rascoApplication/downloadSecuritizationRequestDocument?orderId=${savedOrderId}&utcOffset=${(new Date()).getTimezoneOffset()}`;
    const getFileName = (): string => `${rascoApplicationNumber}-avis-${application.orderDate?.replaceAll('.', '-')}.pdf`;

    const {download} = useDownloadFile({
        apiUrl: getFileUrl(),
        beforeDownload: () => {
            setIsLoading(prev => !prev);
            setLoadingLabel(t("application.submit-wizard.document-loading-label"));
        },
        afterDownload: () => {
            setLoadingLabel(undefined);

            dispatch(setHeaderMenuDisabledState(false));
            dispatch(setHeaderMenuShownState(true));

            dispatch(clear());

            setIsLoading(prev => !prev);

            navigate('/order/application');
        },
        actionFailure: (err: Error) => {
            toast.error<string>(`${t("shared.errors.server-error")} (${err.message})`);

            dispatch(setHeaderMenuDisabledState(false));
            dispatch(setHeaderMenuShownState(true));

            dispatch(clear());

            navigate('/order/application');
        },
        getFileName: getFileName
    });

    if (isLoading) {
        return (
            <div className={cl.loadingContainer}>
                <Logo style={{scale: '0.7'}} animated={true}/>
                {loadingLabel && <div>{loadingLabel}</div>}
            </div>
        )
    }

    return (
        <div className={cl.container}>
            <div>
                <div className={cl.labelContainer}>
                    {t("application.submit-wizard.label")}
                </div>
                <hr/>
            </div>
            <div>
                <div className={`${cl.center} ${cl.bold} ${cl.center} ${cl.mb10} ${cl.fs30}`}>
                    {t("application.submit-wizard.avis-number")}
                    {rascoApplicationNumber
                        ? <span style={{textDecoration: 'underline'}}
                                id={'rasco-application-number'}>
                            {rascoApplicationNumber}
                    </span>
                        : null
                    }
                </div>
                <div className={`${cl.center} ${cl.bold} ${cl.center} ${cl.mb15} ${cl.fs18} download-here-label`}>
                    {t("application.submit-wizard.download-here-label")}
                </div>
                <div className={`${cl.ruleContainer} ${cl.fs18} ${cl.bold} rules-container`}>
                    <div className={'rule-item-1'}>
                        {t("application.submit-wizard.rule-item-1")}
                    </div>
                    <div className={'rule-item-2'}>
                        {t("application.submit-wizard.rule-item-2")}
                    </div>
                    <div className={'rule-item-3'}>
                        {t("application.submit-wizard.rule-item-3")}
                    </div>
                    <div className={'rule-item-4'}>
                        {t("application.submit-wizard.rule-item-4-1")}
                        <span>
                            <a href="mailto: depot@jblogistics.fr">{` depot@jblogistics.fr `}</a>
                        </span>
                        {t("application.submit-wizard.rule-item-4-2")}
                    </div>
                </div>
                <div className={`${cl.center}`}>
                    <div className={`${cl.margin20} ${cl.bold} ${cl.fs18}`}>
                        {t("application.submit-wizard.download-as-pdf-label")}
                    </div>
                    <Button buttonType={ButtonType.Action}
                            id={'download-document-btn'}
                            style={{padding: '10px', fontSize: '17px'}}
                            onClick={download}>
                        <div className={cl.iconButtonContent}>
                            <span><FontAwesomeIcon icon={faFilePdf}/></span>
                            <span>{t("application.submit-wizard.download-btn")}</span>
                        </div>
                    </Button>
                </div>
                <div className={`${cl.center} ${cl.margin20}`}>
                    {t("application.submit-wizard.check-label")}
                </div>
            </div>
        </div>
    );
};

export default SubmitWizard;