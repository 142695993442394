import React from 'react';
import {useAppSelector} from "../../../app/store";
import {selectIsAuth} from "../../../features/authentication/authenticationSelectors";
import {Navigate, Outlet} from "react-router-dom";

const AnonymousLayout: React.FC = () => {
    const isAuth = useAppSelector(selectIsAuth);

    if (isAuth) {
        return <Navigate to={'/order/application'} replace/>
    }

    return <Outlet/>;
};

export default AnonymousLayout;