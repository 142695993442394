import {IApiErrorResourceModelItem} from "../../apiError/IApiErrorResourceModelItem";
import {ResponseResultCode} from "../enums/ResponseResultCode";

export interface ILogin {
    login: string;
    password: string;
    token: string;
}

export const loginApiErrors: Array<IApiErrorResourceModelItem> = [
    {
        resultCode: ResponseResultCode.NotFound,
        field: 'user',
        resourceName: 'login.errors.user-not-found'
    },
    {
        resultCode: ResponseResultCode.Forbidden,
        field: 'user',
        resourceName: 'login.errors.user-not-found'
    },
    {
        resultCode: ResponseResultCode.NotFound,
        field: 'org',
        resourceName: 'login.errors.org-not-found-or-blocked'
    },
    {
        resultCode: ResponseResultCode.Forbidden,
        field: 'org',
        resourceName: 'login.errors.org-not-found-or-blocked'
    },
    {
        resultCode: ResponseResultCode.ServerError,
        resourceName: 'shared.errors.server-error'
    },
    {
        resultCode: ResponseResultCode.ServerError,
        resourceName: 'shared.errors.google-recaptcha-not-loaded-error',
        field: 'recaptcha'
    },
    {
        resultCode: ResponseResultCode.Forbidden,
        resourceName: 'login.errors.recaptcha-score-error',
        field: 'score'
    }
];