import React, {ReactNode} from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {useAppSelector} from "../../store";
import {selectIsAuth} from "../../../features/authentication/authenticationSelectors";

type PrivateRouteProps = {
    children: ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({children}) => {
    const location = useLocation();
    const isAuth = useAppSelector(selectIsAuth);

    if (!isAuth) {
        return <Navigate to={'/login'} replace state={{from: location}}/>
    }

    return <>{children}</>;
};

export default PrivateRoute;