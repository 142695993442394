import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_WEB_API,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});


export default instance;