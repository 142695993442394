import React from 'react';
import cl from './HeaderMenu.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faPerson, faPlus, faRightFromBracket, faUniversity} from "@fortawesome/free-solid-svg-icons";
import {useAppDispatch, useAppSelector} from "../../../../app/store";
import {logOut} from "../../../../features/authentication/authenticationSlice";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {selectIsHeaderMenuDisabled} from "../../../../features/application/applicationSelectors";
import {selectAuthUser} from "../../../../features/authentication/authenticationSelectors";
import {Language} from "../../../../app/types/enums/Language";
import {setHeaderMenuDisabledState} from "../../../../features/application/applicationSlice";
import apiAxios from "../../../../app/api/apiAxios";
import {IResponseModel} from "../../../../app/types/models/IResponseModel";
import {IUser} from "../../../../app/types/models/IUser";
import LanguageSwitcher from "../../LanguageSwitcher/LanguageSwitcher";
import useMediaQuery from "../../../../hooks/useMediaQuery";

const HeaderMenu: React.FC = () => {
    const dispatch = useAppDispatch();
    const {t, i18n} = useTranslation();
    const isDisabled = useAppSelector(selectIsHeaderMenuDisabled);
    const user = useAppSelector(selectAuthUser);
    const isMaxWidth690px = useMediaQuery('(max-width: 690px)');

    const changeLanguage = async (lng: Language) => {
        if (!user)
            return;

        try {
            dispatch(setHeaderMenuDisabledState(true));

            const response = await apiAxios.post<IResponseModel>('api/user/changeLanguage', {
                id: user.id,
                language: lng
            } as IUser);

            if (response.status === 200 || response.status === 204) {
                await i18n.changeLanguage(Language[lng].toString().toLowerCase());
            }

            dispatch(setHeaderMenuDisabledState(false));
        } catch (e) {
            if (e instanceof Error) {
                alert(e.message);
            }

            dispatch(setHeaderMenuDisabledState(false));
        }
    }

    const clickHandler = (ev: React.MouseEvent<HTMLAnchorElement>) => {
        if (isDisabled)
            ev.preventDefault();
    }

    return (
        <div className={cl.container}>
            <div className={cl.separateMenuHeaderItemMargin}>
                <LanguageSwitcher disabled={isDisabled}
                                  hideText={isMaxWidth690px}
                                  change={changeLanguage}/>
            </div>
            <NavLink to={'/order/application'}
                     title={t("menu.new-order")}
                     onClick={clickHandler}
                     className={({isActive}) => isActive ? `${cl.menuItem} ${cl.selected}` : cl.menuItem}>
                <span>
                    <FontAwesomeIcon icon={faPlus}/>
                </span>
                <span>
                    {t("menu.new-order")}
                </span>
            </NavLink>
            <NavLink to={'/orders'}
                     title={t("menu.orders")}
                     onClick={clickHandler}
                     className={({isActive}) => isActive ? `${cl.menuItem} ${cl.selected}` : cl.menuItem}>
                <span>
                    <FontAwesomeIcon icon={faList}/>
                </span>
                <span>
                    {t("menu.orders")}
                </span>
            </NavLink>
            {/*<NavLink to={'/rascofirms'}*/}
            {/*         title={t("menu.rascofirms")}*/}
            {/*         onClick={clickHandler}*/}
            {/*         className={({isActive}) => isActive ? `${cl.menuItem} ${cl.selected}` : cl.menuItem}>*/}
            {/*    <span>*/}
            {/*        <FontAwesomeIcon icon={faUniversity}/>*/}
            {/*    </span>*/}
            {/*    <span>*/}
            {/*        {t("menu.rascofirms")}*/}
            {/*    </span>*/}
            {/*</NavLink>*/}
            <NavLink to={'/'}
                     className={cl.menuItem}
                     title={t("menu.logout")}
                     onClick={(ev) => {
                         ev.preventDefault();

                         if (!isDisabled)
                             dispatch(logOut());
                     }}>
                <span style={{color: 'red'}}>
                    <FontAwesomeIcon icon={faRightFromBracket}/>
                </span>
                <span>
                 {t("menu.logout")}
                </span>
            </NavLink>
        </div>
    );
};

export default HeaderMenu;
