import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../app/store";
import {
    selectCompanyBranchItem,
    selectRascoApplication
} from "../../../../../features/rascoApplication/rascoApplicationSelectors";
import useIsFirstRender from "../../../../../hooks/useIsFirstRender";
import {
    setRascoApplicationWizardState
} from "../../../../../features/rascoApplicationWizard/rascoApplicationWizardSlice";
import {IRascoApplicationWizardState} from "../../../../../app/types/redux/IRascoApplicationWizardState";
import {
    setConfirmation,
    setEmail,
    setEmployeeName,
    setOrderDate,
    setUtcOffset
} from "../../../../../features/rascoApplication/rascoApplicationSlice";
import moment from "moment";
import RelativeLoadingScreen from "../../../../../components/ui/LoadingScreen/RelativeLoadingScreen";
import cl from './ClientWizard.module.css';
import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import {ChangedEventArgs, DatePickerComponent} from "@syncfusion/ej2-react-calendars";
import WizardInput from "../../components/WizardInput/WizardInput";
import {useLocation, useNavigate} from "react-router-dom";
import useWizard from "../../../../../components/ui/Wizard/utils/useWizard";

const footerInitialState: IRascoApplicationWizardState = {
    isPreviousButtonDisabled: true,
    showPreviousButton: false,
    isNextButtonDisabled: true,
    showNextButton: true,
    isCancelButtonDisabled: false,
    showCancelButton: true,
    showProgressBar: true,
    showFooter: true,
    cancelButtonAlign: 'left',
    nextButtonAlign: 'right',
    previousButtonAlign: 'right'
};

function validateEmail(email?: string): boolean {
    return (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email));
}

function isValid(confirmation?: string, employeeName?: string, email?: string): boolean {
    if (!employeeName || validateEmail(email)) {
        return false;
    }

    return (!(!confirmation || confirmation !== 'confirm'));
}

const ClientWizard: React.FC = () => {
    const {id} = useAppSelector(selectRascoApplication);
    const isFirstRender = useIsFirstRender();
    const dispatch = useAppDispatch();
    const company = useAppSelector(selectCompanyBranchItem);
    const {confirmation, email, orderDate, employeeName} = useAppSelector(selectRascoApplication);
    const {t} = useTranslation();
    const location = useLocation();
    const {goToStep} = useWizard();
    const navigate = useNavigate();

    useEffect(() => {
        if (isFirstRender) {
            dispatch(setRascoApplicationWizardState(footerInitialState));
        }
    }, [dispatch, isFirstRender])

    useEffect(() => {
        dispatch(setUtcOffset((new Date()).getTimezoneOffset()));

        if (!id) {
            if (!orderDate) {
                dispatch(setOrderDate(moment().format('DD.MM.YYYY')));
            }

            if (company !== null && company !== undefined &&
                company.companyBranchItemEmails !== undefined &&
                company.companyBranchItemEmails !== null &&
                company.companyBranchItemEmails.length > 0) {
                let item = company.companyBranchItemEmails[0];

                if (item !== null) {
                    dispatch(setEmail(item.email));
                }
            }
        }

        if (id !== null && id !== undefined &&
            location.state !== null && location.state !== undefined &&
            location.state.goToStep !== null && location.state.goToStep !== undefined && isFirstRender) {
            dispatch(setConfirmation("confirm"));

            goToStep(location.state.goToStep);

            navigate(location.pathname, {replace: true});
        }
    }, [dispatch, id, company]);

    useEffect(() => {
        dispatch(setRascoApplicationWizardState(
            isValid(confirmation, employeeName, email)
                ? {...footerInitialState, isNextButtonDisabled: false}
                : footerInitialState
        ));
    }, [dispatch, confirmation, email, orderDate, employeeName]);

    if (!company || !orderDate || (id !== null && id !== undefined &&
        location.state !== null && location.state !== undefined &&
        location.state.goToStep !== null && location.state.goToStep !== undefined)) {
        return (
            <div style={{height: '100%', width: '100%', position: 'relative'}}>
                <RelativeLoadingScreen/>
            </div>
        );
    }

    return (
        <div className={cl.container} id={'client-wizard'}>
            <div>
                {t("application.start-page.new-order")}
            </div>
            <div>
                <Container fluid className={cl.h100}>
                    <Row className={cl.h100}>
                        <Col sm className={cl.col}>
                            <div className={cl.columnHeader}>
                                {t("application.client-wizard.column-1-header")}
                            </div>

                            <div className='input-container'>
                                <div className='label'>
                                    {t("application.client-wizard.client")}
                                </div>
                                <div className={cl.clientInfo} id={'client-info-container'}>
                                    <div>
                                        <b>
                                            {company.companyName}
                                        </b>
                                    </div>
                                    {company.companyBranchItemAddress?.address !== null
                                        ? <div>
                                            <b>
                                                {company.companyBranchItemAddress.address}
                                            </b>
                                        </div>
                                        : null
                                    }
                                    {company.regulatingNumber
                                        ? <div>
                                            {company.regulatingNumber}
                                        </div>
                                        : null
                                    }
                                    {company.licence
                                        ? <div>
                                            {company.licence}
                                        </div>
                                        : null
                                    }
                                </div>

                                <div className={cl.afterLabel}>
                                    {t("application.client-wizard.client-confirmation-label")}
                                </div>
                            </div>

                            <div className='input-container'>
                                <div className='label required'>
                                    {t("application.client-wizard.email-label")}
                                </div>

                                <WizardInput showClearButton={false}
                                             id={'email'}
                                             value={email}
                                             onChange={(ev) => {
                                                 dispatch(setEmail(ev.target.value));
                                             }}
                                             placeholder={t("application.client-wizard.email")}/>
                                {validateEmail(email)
                                    ? <div
                                        className='server-error-label'>{t("application.client-wizard.email-error-label")}</div>
                                    : null
                                }
                            </div>

                        </Col>
                        <Col sm className={`${cl.brBlack} ${cl.col}`}>
                            <div className={cl.columnHeader}>
                                {t("application.client-wizard.column-2-header")}
                            </div>

                            <div className='input-container syncfusion-control-input-container'>
                                <div className='label required'>
                                    {t("application.client-wizard.order-date-label")}
                                </div>

                                <DatePickerComponent placeholder={t("application.client-wizard.order-date-label")}
                                                     id={'order-date'}
                                                     value={moment(orderDate, 'DD.MM.YYYY').toDate()}
                                                     change={(ev: ChangedEventArgs) => {
                                                         dispatch(setOrderDate(moment(ev.value!).format("DD.MM.YYYY")))
                                                     }}
                                                     min={moment().toDate()}
                                                     showClearButton={false}
                                                     format='dd.MM.yyyy'/>

                                <div className={cl.afterLabel}>
                                    {t("application.client-wizard.order-date-confirmation-label")}
                                </div>
                            </div>

                            <div className='input-container'>
                                <div className='label required'>
                                    {t("application.client-wizard.employee-name")}
                                </div>

                                <WizardInput showClearButton={false}
                                             id={'employee-name'}
                                             value={employeeName}
                                             onChange={(ev) => {
                                                 dispatch(setEmployeeName(ev.target.value));
                                             }}
                                             placeholder={t("application.client-wizard.employee-name")}/>

                                <div className={cl.afterLabel}>
                                    {t("application.client-wizard.employee-confirmation-label")}
                                </div>
                            </div>
                        </Col>
                        <Col sm className={`${cl.center} ${cl.col}`}>
                            <div className={cl.afterLabel}>
                                <div>
                                    <h5>
                                        <b>
                                            {t("application.client-wizard.confirmation-label-1")}
                                        </b>
                                    </h5>
                                </div>
                                {t("application.client-wizard.confirmation-label-2")}
                            </div>
                            <div className='input-container'>
                                <div className='label required'>
                                    {t("application.client-wizard.confirmation-input-label")}
                                </div>
                                <WizardInput showClearButton={false}
                                             id={'confirm'}
                                             value={confirmation || ''}
                                             onChange={(ev) => {
                                                 dispatch(setConfirmation(ev.target.value));
                                             }}
                                             placeholder={t("application.client-wizard.confirmation-input-label")}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default ClientWizard;