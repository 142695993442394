import React, {useEffect, useState} from "react";
import RelativeLoadingScreen from "../../../components/ui/LoadingScreen/RelativeLoadingScreen";
import {useAppDispatch, useAppSelector} from "../../../app/store";
import {selectAuthUser} from "../../../features/authentication/authenticationSelectors";
import {selectCompanyBranchItem} from "../../../features/rascoApplication/rascoApplicationSelectors";
import apiAxios from "../../../app/api/apiAxios";
import {IResponseModel, isResponseModel} from "../../../app/types/models/IResponseModel";
import {ICompanyBranchItem} from "../../../app/types/models/ICompanyBranchItem";
import {
    clear,
    setCompanyBranchItem, setRascoApplication,
} from "../../../features/rascoApplication/rascoApplicationSlice";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {IRascoApplicationState} from "../../../app/types/redux/IRascoApplicationState";
import useIsFirstRender from "../../../hooks/useIsFirstRender";
import {
    setHeaderMenuDisabledState,
    setHeaderMenuLabel,
    setHeaderMenuShownState
} from "../../../features/application/applicationSlice";
import {setProgressBarItems} from "../../../features/rascoApplicationWizard/rascoApplicationWizardSlice";
import {WizardProgressBarStatus} from "../../../app/types/redux/IRascoApplicationWizardState";
import {selectIsHeaderMenuShown} from "../../../features/application/applicationSelectors";
import useMediaQuery from "../../../hooks/useMediaQuery";
import {
    selectRascoApplicationWizardState
} from "../../../features/rascoApplicationWizard/rascoApplicationWizardSelectors";
import AnimatedStep from "../../../components/ui/Wizard/components/AnimatedStep/AnimatedStep";
import TruckWizard from "./wizards/TruckWizard/TruckWizard";
import Wizard from "../../../components/ui/Wizard/Wizard";
import WizardFooter from "./components/WizardFooter/WizardFooter";
import ClientWizard from "./wizards/ClientWizard/ClientWizard";
import TruckIdentificationWizard from "./wizards/TruckIdentificationWizard/TruckIdentificationWizard";
import CargoWizard from "./wizards/CargoWizard/CargoWizard";
import ConfirmationWizard from "./wizards/ConfirmationWizard/ConfirmationWizard";
import SubmitWizard from "./wizards/SubmitWizard/SubmitWizard";

const heightWithProgressBar = 'calc(100% - 130px)';

const ApplicationOrderWizard: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const user = useAppSelector(selectAuthUser);
    const company = useAppSelector(selectCompanyBranchItem);
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const isFirstRender = useIsFirstRender();
    const isHeaderMenuShown = useAppSelector(selectIsHeaderMenuShown);
    const {showProgressBar} = useAppSelector(selectRascoApplicationWizardState);
    const isMaxWidth600Match = useMediaQuery('(max-width: 600px)');
    const previousStep = React.useRef<number>(0);

    useEffect(() => {
        if (isFirstRender) {
            dispatch(clear());

            dispatch(setHeaderMenuShownState(false));
            dispatch(setHeaderMenuDisabledState(true));

            dispatch(setHeaderMenuLabel(!isHeaderMenuShown ? t("application.shared.header-menu-label") : ''));

            dispatch(setProgressBarItems([
                {
                    orderStep: 0,
                    status: WizardProgressBarStatus.UnComplete,
                    label: t("application.wizard-progress-bar.first-wizard-title"),
                    description: t("application.wizard-progress-bar.first-wizard-description"),
                },
                {
                    orderStep: 1,
                    status: WizardProgressBarStatus.UnComplete,
                    label: t("application.wizard-progress-bar.truck-identification-wizard-title"),
                    description: t("application.wizard-progress-bar.truck-identification-wizard-description")
                },
                {
                    orderStep: 2,
                    status: WizardProgressBarStatus.UnComplete,
                    label: t("application.wizard-progress-bar.second-wizard-title"),
                    description: t("application.wizard-progress-bar.second-wizard-description"),
                },
                {
                    orderStep: 3,
                    status: WizardProgressBarStatus.UnComplete,
                    label: t("application.wizard-progress-bar.third-wizard-title"),
                    description: t("application.wizard-progress-bar.third-wizard-description"),
                },
                {
                    orderStep: 4,
                    status: WizardProgressBarStatus.UnComplete,
                    label: t("application.wizard-progress-bar.fourth-wizard-title"),
                    description: t("application.wizard-progress-bar.fourth-wizard-description"),
                }
            ]));
        }

        if (user && isFirstRender) {
            (async () => {
                setIsLoading(prev => !prev);

                if (id !== undefined && id !== null && Number(id) > 0) {
                    try {
                        const response = await apiAxios.get<IResponseModel | IRascoApplicationState>(`api/order/getRascoApplication?id=${id}`);
                        if (!isResponseModel(response.data)) {
                            dispatch(setRascoApplication(response.data));
                        }
                    } catch (error) {
                        if (error instanceof Error) {
                            toast.error<string>(`${t("shared.errors.server-error")} (${error.message})`);

                            dispatch(setHeaderMenuShownState(true));
                            dispatch(setHeaderMenuDisabledState(false));

                            navigate('/order/application');
                        }
                    }
                }

                try {
                    const response = await apiAxios.get<IResponseModel | ICompanyBranchItem>(`api/company/getCompanyBranchItem?id=${user.companyBranchItemId}`);
                    if (!isResponseModel(response.data)) {
                        dispatch(setCompanyBranchItem(response.data))
                    }
                } catch (error) {
                    if (error instanceof Error) {
                        toast.error<string>(`${t("shared.errors.server-error")} (${error.message})`);

                        dispatch(setHeaderMenuShownState(true));
                        dispatch(setHeaderMenuDisabledState(false));

                        navigate('/order/application');
                    }
                }

                setIsLoading(prev => !prev);
            })();
        }
    }, [dispatch, user, id, navigate, t, isFirstRender, isHeaderMenuShown]);


    if (isLoading || !user || !company) {
        return (
            <div style={{height: '100%', width: '100%', position: 'relative'}}>
                <RelativeLoadingScreen/>
            </div>
        );
    }

    return (
        <Wizard footer={<WizardFooter/>}>
            <AnimatedStep height={showProgressBar && !isMaxWidth600Match ? heightWithProgressBar : undefined}
                          previousStep={previousStep}>
                <ClientWizard/>
            </AnimatedStep>
            <AnimatedStep height={showProgressBar && !isMaxWidth600Match ? heightWithProgressBar : undefined}
                          previousStep={previousStep}>
                <TruckIdentificationWizard/>
            </AnimatedStep>
            <AnimatedStep height={showProgressBar && !isMaxWidth600Match ? heightWithProgressBar : undefined}
                          previousStep={previousStep}>
                <TruckWizard/>
            </AnimatedStep>
            <AnimatedStep height={showProgressBar && !isMaxWidth600Match ? heightWithProgressBar : undefined}
                          previousStep={previousStep}>
                <CargoWizard/>
            </AnimatedStep>
            <AnimatedStep height={showProgressBar && !isMaxWidth600Match ? heightWithProgressBar : undefined}
                          previousStep={previousStep}>
                <ConfirmationWizard/>
            </AnimatedStep>
            <AnimatedStep height={'100%'} previousStep={previousStep}>
                <SubmitWizard/>
            </AnimatedStep>
        </Wizard>
    );
}

export default ApplicationOrderWizard;