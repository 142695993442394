import {IWizard} from "./wizardTypes";
import React from "react";
import wizardContext from "./wizardContext";

const useWizard = () => {
    const context = React.useContext(wizardContext);

    if (!context) {
        throw new Error('Wizard component not defined as a parent');
    } else {
        return context as IWizard;
    }
}

export default useWizard;