import React, {ComponentPropsWithoutRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass, faXmark} from "@fortawesome/free-solid-svg-icons";
import cl from './WizardInput.module.css';

type WizardInputProps = {
    showClearButton: boolean;
    containerWidth?: string;
    onClear?: () => void,
    toUpperCase?: boolean;
    allowDecimalPlaces?: number;
} & ComponentPropsWithoutRef<'input'>;

const WizardInput: React.FC<WizardInputProps> =
    ({
         showClearButton = false,
         onClear,
         value,
         onChange,
         type,
         toUpperCase,
         containerWidth,
         min,
         max,
         allowDecimalPlaces,
         ...rest
     }) => {
        const [state, setState] = useState(value);

        const onInputChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
            if (toUpperCase !== undefined && toUpperCase) {
                if (type === 'number' && min !== undefined && max !== undefined) {
                    if (allowDecimalPlaces !== undefined) {
                        setState(Math.max(Number(min), Math.min(Number(max), Number(ev.target.value))).toFixed(allowDecimalPlaces))
                    } else {
                        setState(Math.max(Number(min), Math.min(Number(max), Number(ev.target.value))))
                    }
                } else {
                    setState(ev.target.value !== undefined ? ev.target.value.toUpperCase() : ev.target.value);
                }
            } else {
                if (type === 'number' && min !== undefined && max !== undefined) {
                    if (allowDecimalPlaces !== undefined) {
                        setState(Math.max(Number(min), Math.min(Number(max), Number(ev.target.value))).toFixed(allowDecimalPlaces))
                    } else {
                        setState(Math.max(Number(min), Math.min(Number(max), Number(ev.target.value))))
                    }
                } else {
                    setState(ev.target.value);
                }
            }

            if (onChange) {
                onChange(ev);
            }
        };

        const onClearBtnClick = () => {
            setState('');

            if (onClear) {
                onClear();
            }
        }

        return (
            <div style={containerWidth ? {position: 'relative', width: containerWidth} : {position: 'relative'}}>
                <input type={type === 'search' ? 'text' : type}
                       onChange={onInputChange}
                       className={cl.input}
                       data-type={type}
                       value={state}
                       {...rest}
                />
                {type === "search"
                    ? <span className={cl.searchBtn}><FontAwesomeIcon icon={faMagnifyingGlass}/></span>
                    : null
                }
                {state && showClearButton
                    ?
                    <span className={cl.clearBtn} onClick={onClearBtnClick}><FontAwesomeIcon icon={faXmark}/></span>
                    : null
                }
            </div>
        );
    };

export default WizardInput;