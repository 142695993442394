import React, {ReactNode} from 'react';
import {getInstance, L10n} from "@syncfusion/ej2-base";
import {withTranslation} from "react-i18next";
import {DataManager, Query} from "@syncfusion/ej2-data";
import {i18n} from "i18next";
import {
    FailureEventArgs,
    FilterSettingsModel,
    PageSettingsModel, RowDeselectEventArgs,
    RowSelectEventArgs
} from "@syncfusion/ej2-react-grids";
import {GridComponent as Grid} from "@syncfusion/ej2-react-grids/src/grid/grid.component";
import instance from "../../../app/api/apiAxios";
import {WithCredentialsWebApiAdaptor} from "../SyncfusionComboBox/SyncfusionComboBox";
import {IResponseModel} from "../../../app/types/models/IResponseModel";
import {ResponseResultCode} from "../../../app/types/enums/ResponseResultCode";

L10n.load({
    'ru-RU': {
        'grid': {
            'EmptyDataSourceError': 'DataSource не должен быть пустым при начальной загрузке, так как столбцы генерируются из dataSource в AutoGenerate Column Grid.',
            'EmptyRecord': 'Нет данных для отображения',
            'Item': 'Элемент',
            'Items': 'Элементы',
        },
        'pager': {
            'currentPageInfo': '{0} из {1} страниц',
            'firstPageTooltip': 'К первой странице',
            'lastPageTooltip': 'К последней странице',
            'nextPageTooltip': 'К следующей странице',
            'nextPagerTooltip': 'Перейти к следующим элементам пейджера',
            'previousPageTooltip': 'К предыдущей странице',
            'previousPagerTooltip': 'Перейти к предыдущим элементам пейджера',
            'totalItemsInfo': '({0} элементов)'
        },
        'daterangepicker': {
            placeholder: 'Выберите промежуток',
            startLabel: 'Начало',
            endLabel: 'Окончание',
            applyText: 'Применить',
            cancelText: 'Отменить',
            selectedDays: 'Выбранные дни',
            days: 'дней(день)',
            customRange: 'Промежуток'
        }
    },
    'en-EN': {
        'grid': {
            'EmptyDataSourceError': 'DataSource must not be empty at initial load since columns are generated from dataSource in AutoGenerate Column Grid',
            'EmptyRecord': 'No records to display',
            'Item': 'Item',
            'Items': 'Items',
        },
        'pager': {
            'currentPageInfo': '{0} of {1} pages',
            'firstPageTooltip': 'Go to first page',
            'lastPageTooltip': 'Go to last page',
            'nextPageTooltip': 'Go to next page',
            'nextPagerTooltip': 'Go to next pager items',
            'previousPageTooltip': 'Go to previous page',
            'previousPagerTooltip': 'Go to previous pager items',
            'totalItemsInfo': '({0} items)'
        },
        'daterangepicker': {
            placeholder: 'Select date interval',
            startLabel: 'Select a start date for the interval',
            endLabel: 'Select the end date of the interval',
            applyText: 'Apply',
            cancelText: 'Cancel',
            selectedDays: 'Selected days',
            days: 'days(day)',
            customRange: 'interval'
        }
    }
});

type GridComponentProps = {
    url: string;
    query?: Query;
    i18n: i18n;
    allowPaging?: boolean;
    allowSorting?: boolean;
    allowFiltering?: boolean;
    allowSelection?: boolean;
    heightMinus?: number;
    filterSettings?: FilterSettingsModel;
    pageSettings?: PageSettingsModel;
    refresh?: number;
    id: string;
    onRowClick?: (ev: FailureEventArgs) => void;
    onGridCreated?: () => void;
    rowSelected?: (ev: RowSelectEventArgs) => void;
    rowDeselected?: (ev: RowDeselectEventArgs) => void;
    dataBound?: (ev: object) => void;
    container: string;
    children: ReactNode | ReactNode[];
}

type GridComponentState = {
    query?: Query;
    dataSource: DataManager;
    locale: string;
    height: string;
    width: string;
    allowPaging: boolean;
    allowSorting: boolean;
    allowFiltering: boolean;
    allowSelection: boolean;
    heightMinus: number;
    filterSettings: FilterSettingsModel;
    pageSettings: PageSettingsModel;
    refresh?: number;
}

class GridComponent extends React.Component<GridComponentProps, GridComponentState> {
    constructor(props: GridComponentProps) {
        super(props);

        let filterSettings = this.props.filterSettings
            ? this.props.filterSettings
            : {mode: 'Immediate', immediateModeDelay: 1000} as FilterSettingsModel;

        let pageSettings = this.props.pageSettings
            ? this.props.pageSettings
            : {
                enableExternalMessage: false,
                pageSize: 30,
            }

        this.state = {
            query: this.props.query,
            dataSource: new DataManager({
                adaptor: new WithCredentialsWebApiAdaptor(),
                url: `${process.env.REACT_APP_WEB_API}${this.props.url}`,
            }),
            locale: `${this.props.i18n.language}-${this.props.i18n.language.toUpperCase()}`,
            height: '100%',
            width: '100%',
            allowPaging: this.props.allowPaging ?? true,
            allowSorting: this.props.allowSorting ?? true,
            allowFiltering: this.props.allowFiltering ?? true,
            allowSelection: this.props.allowSelection ?? true,
            heightMinus: this.props.heightMinus ?? 0,
            filterSettings: filterSettings,
            pageSettings: pageSettings,
            refresh: this.props.refresh,
        };


        this.onActionFailure = this.onActionFailure.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onCreated = this.onCreated.bind(this);
        this.rowSelected = this.rowSelected.bind(this);
        this.rowDeselected = this.rowDeselected.bind(this);
        this.onRowDoubleClick = this.onRowDoubleClick.bind(this);
        this.onDataBound = this.onDataBound.bind(this);
    }

    private gridRef: Grid | null = null;

    componentDidUpdate(prevProps: GridComponentProps, prevState: GridComponentState) {
        if (this.props.query && prevProps.query !== this.props.query) {
            this.setState({
                query: this.props.query
            });
        }

        if (prevProps.refresh !== this.props.refresh) {
            if (this.gridRef) {
                this.gridRef.refresh()
            }
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize = () => {
        this.resizeGrid();
    };

    onActionFailure(ev: FailureEventArgs) {
        let _ev = ev as unknown as { name: string, error: { error: XMLHttpRequest } };
        if (_ev.name && _ev.error && _ev.error.error && _ev.name === 'actionFailure' && _ev.error.error.status === 401) {
            (async () => {

                try {
                    const response = await instance.post<IResponseModel>('api/authentication/refreshToken');
                    if (response && response.data.resultCode === ResponseResultCode.Ok && this.gridRef) {
                        this.gridRef.refresh();
                    }
                } catch (error) {
                    if (error instanceof Error) {
                        console.log(error.message);
                    }
                }
            })();
        }
    }

    onRowDoubleClick(ev: FailureEventArgs) {
        if (this.props.onRowClick) {
            this.props.onRowClick(ev);
        }
    }

    onLoad() {
        this.resizeGrid();
    }

    onCreated() {
        this.resizeGrid();

        if (this.props.onGridCreated) {
            this.props.onGridCreated();
        }
    }

    resizeGrid() {
        let gridElem: any = getInstance(document.getElementById(this.props.id) || 'grid', Grid);
        if (gridElem !== null && gridElem !== undefined) {
            let heightToMinus = 0;
            let container = document.querySelector(this.props.container);

            if (container) {
                heightToMinus = Array.from(container.children)
                    .filter(item => item.id !== this.props.id)
                    .reduce((acc, elem) => acc + elem.clientHeight, 0);
            } else {
                return;
            }

            let pagerHeight = 0;
            let pager = document.querySelector('.e-pager');
            if (pager) {
                pagerHeight = pager.clientHeight;
            }

            let headerHeight = 0;
            let header = document.querySelector('.e-gridheader');
            if (header) {
                headerHeight = header.clientHeight;
            }

            gridElem.height = (container.clientHeight - heightToMinus - pagerHeight - headerHeight - this.state.heightMinus - 4) + 'px';
        }
    }

    rowSelected(ev: RowSelectEventArgs) {
        if (this.props.rowSelected) {
            this.props.rowSelected(ev);
        }
    }

    rowDeselected(ev: RowDeselectEventArgs) {
        if (this.props.rowDeselected) {
            this.props.rowDeselected(ev);
        }
    }

    onDataBound(ev: object) {
        if (this.props.dataBound) {
            this.props.dataBound(ev);
        }
    }

    render() {
        return (
            <Grid ref={rf => this.gridRef = rf}
                  id={this.props.id}
                  locale={this.state.locale}
                  query={this.state.query}
                  dataSource={this.state.dataSource}
                  filterSettings={this.state.filterSettings}
                  pageSettings={this.state.pageSettings}
                  loadingIndicator={{indicatorType: 'Shimmer'}}
                  height={this.state.height}
                  width={this.state.width}
                  allowPaging={this.state.allowPaging}
                  allowSorting={this.state.allowSorting}
                  allowFiltering={this.state.allowFiltering}
                  allowSelection={this.state.allowSelection}
                  rowSelected={this.rowSelected}
                  rowDeselected={this.rowDeselected}
                  actionFailure={this.onActionFailure}
                  load={this.onLoad}
                  created={this.onCreated}
                  recordDoubleClick={this.onRowDoubleClick}
                  dataBound={this.onDataBound}>
                {this.props.children}
            </Grid>
        )
    }
}

export default React.memo(withTranslation()(GridComponent));