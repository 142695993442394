import React from 'react';
import cl from './Header.module.css';
import Logo from "../Logo/Logo";
import HeaderMenu from "./HeaderMenu/HeaderMenu";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../app/store";
import {selectHeaderMenuLabel, selectIsHeaderMenuShown} from "../../../features/application/applicationSelectors";
import {selectAuthUser} from "../../../features/authentication/authenticationSelectors";

const Header: React.FC = () => {
    const {t} = useTranslation();
    const isHeaderMenuShown = useAppSelector(selectIsHeaderMenuShown);
    const headerMenuLabel = useAppSelector(selectHeaderMenuLabel);
    const user = useAppSelector(selectAuthUser);

    return (
        <header className={cl.header} >
            <div className={cl.logoColumn} onClick={()=>{window.location.reload();}}>
                <div className={cl.logoContainer} >
                    <div>
                        <div>
                            <Logo/>
                        </div>
                    </div>
                </div>
                <div className={cl.textContainer}>
                    {t("shared.rasco")}
                </div>
                {user !== null && user.companyName
                    ? <div className={cl.companyNameContainer}>
                        {user.companyName}
                    </div>
                    : null
                }
            </div>
            <div>
                {isHeaderMenuShown && <HeaderMenu/>}
                {!isHeaderMenuShown && headerMenuLabel && <div className={cl.headerMenuLabel}>{headerMenuLabel}</div>}
            </div>
        </header>
    );
};

export default Header;
