import React, {useEffect} from 'react';
import cl from './TruckIdentificationWizard.module.css';
import {useAppDispatch, useAppSelector} from "../../../../../app/store";
import {
    setRascoApplicationWizardState
} from "../../../../../features/rascoApplicationWizard/rascoApplicationWizardSlice";
import {IRascoApplicationWizardState} from "../../../../../app/types/redux/IRascoApplicationWizardState";
import {useTranslation} from "react-i18next";
import {selectRascoApplication} from "../../../../../features/rascoApplication/rascoApplicationSelectors";
import {
    setDoubleTrailerLicencePlate,
    setTrailerLicencePlate, setTransportType,
    setTruckLicencePlate
} from "../../../../../features/rascoApplication/rascoApplicationSlice";
import WizardInput from "../../components/WizardInput/WizardInput";
import {DropDownListComponent, PopupEventArgs} from "@syncfusion/ej2-react-dropdowns";
import {TransportType} from "../../../../../app/types/enums/TransportType";

const footerInitialState: IRascoApplicationWizardState = {
    isPreviousButtonDisabled: false,
    showPreviousButton: true,
    isNextButtonDisabled: true,
    showNextButton: true,
    isCancelButtonDisabled: false,
    showCancelButton: true,
    showProgressBar: true,
    showFooter: true,
    cancelButtonAlign: 'left',
    nextButtonAlign: 'right',
    previousButtonAlign: 'right'
}

function isValid(truckLicencePlate?: string, transportType?: TransportType | undefined): boolean {
    return truckLicencePlate !== undefined && truckLicencePlate !== null && truckLicencePlate !== '' &&
        transportType !== undefined && transportType != null;
}


const TruckIdentificationWizard = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {
        truckLicencePlate,
        trailerLicencePlate,
        doubleTrailerLicencePlate,
        transportType
    } = useAppSelector(selectRascoApplication);

    const transportTypes = [
        {id: '0', text: t("shared.transport-type.0")},
        {id: '1', text: t("shared.transport-type.1")},
        {id: '2', text: t("shared.transport-type.2")}
    ];

    useEffect(() => {
        dispatch(setRascoApplicationWizardState(footerInitialState))
    }, [dispatch])

    useEffect(() => {
        dispatch(setRascoApplicationWizardState(
            isValid(truckLicencePlate, transportType)
                ? {...footerInitialState, isNextButtonDisabled: false}
                : footerInitialState
        ));
    }, [dispatch, truckLicencePlate, trailerLicencePlate, doubleTrailerLicencePlate, transportType]);

    return (
        <div className={cl.container}>
            <div>
                {t("application.wizard-progress-bar.truck-identification-wizard-title")}
            </div>
            <div>
                <div className={cl.tableContainer}>
                    <div className={cl.tablePreLabel}>
                        {t("application.truck-identification-wizard.label")}
                    </div>
                    <table className={cl.responsiveTable}>
                        <tbody>
                        <tr>
                            <th scope="row">{t("application.truck-identification-wizard.registration-label")}</th>
                            <td className={'syncfusion-control-input-container'}>
                                <DropDownListComponent id='transport-type'
                                                       dataSource={transportTypes}
                                                       placeholder={t("application.truck-identification-wizard.registration-label")}
                                                       fields={{text: 'text', value: 'id'}}
                                                       value={transportType !== undefined ? transportType.toString() : undefined}
                                                       change={(ev) => {
                                                           if (ev?.itemData !== null) {
                                                               dispatch(setTransportType(TransportType[TransportType[Number(ev.itemData.id)] as keyof typeof TransportType]));
                                                           } else {
                                                               dispatch(setTransportType(undefined));
                                                           }
                                                       }}/>
                                {transportType === undefined
                                    ? <div className='server-error-label'
                                           style={{textAlign: 'left'}}>
                                        {t("shared.errors.required-error").replace('{0}', t("application.truck-identification-wizard.registration-label"))}
                                    </div>
                                    : null
                                }
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">{t("application.truck-identification-wizard.truck")}</th>
                            <td>
                                <WizardInput showClearButton={false}
                                             id={'truck-licence-plate'}
                                             value={truckLicencePlate}
                                             toUpperCase={true}
                                             onChange={(ev) => {
                                                 dispatch(setTruckLicencePlate(ev.target.value ? ev.target.value.toUpperCase() : ''));
                                             }}
                                             placeholder={t("application.truck-identification-wizard.truck")}/>
                                {!truckLicencePlate
                                    ? <div className='server-error-label'
                                           style={{textAlign: 'left'}}>
                                        {t("shared.errors.required-error").replace('{0}', t("application.truck-identification-wizard.truck"))}
                                    </div>
                                    : null
                                }
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">{t("application.truck-identification-wizard.trailer")}</th>
                            <td>
                                <WizardInput showClearButton={false}
                                             id={'trailer-licence-plate'}
                                             value={trailerLicencePlate}
                                             toUpperCase={true}
                                             onChange={(ev) => {
                                                 dispatch(setTrailerLicencePlate(ev.target.value ? ev.target.value.toUpperCase() : ''));
                                             }}
                                             placeholder={t("application.truck-identification-wizard.trailer")}/>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">{t("application.truck-identification-wizard.double-trailer")}</th>
                            <td>
                                <WizardInput showClearButton={false}
                                             id={'double-trailer-licence-plate'}
                                             value={doubleTrailerLicencePlate}
                                             toUpperCase={true}
                                             onChange={(ev) => {
                                                 dispatch(setDoubleTrailerLicencePlate(ev.target.value ? ev.target.value.toUpperCase() : ''));
                                             }}
                                             placeholder={t("application.truck-identification-wizard.double-trailer")}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TruckIdentificationWizard;