import React from 'react';
import cl from './OrderCsdDownloadButtonTemplate.module.css';
import {useTranslation} from "react-i18next";
import useDownloadFile from "../../../../../hooks/useDownloadFile";
import {toast} from "react-toastify";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {OrderStatus} from "../../../../../app/types/enums/OrderStatus";

type OrderCsdDownloadTemplateProps = {
    showLoadingScreen: () => void;
    hideLoadingScreen: () => void;
    data: any;
}

const OrderCsdDownloadButtonTemplate: React.FC<OrderCsdDownloadTemplateProps> = ({
                                                                                     showLoadingScreen,
                                                                                     hideLoadingScreen,
                                                                                     data
                                                                                 }) => {
    const {t} = useTranslation();
    const getFileUrl = (): string => `/api/order/downloadRascoOrderCsdDocument?orderId=${data.id}&utcOffset=${(new Date()).getTimezoneOffset()}`;

    const {download} = useDownloadFile({
        apiUrl: getFileUrl(),
        beforeDownload: () => showLoadingScreen(),
        afterDownload: () => hideLoadingScreen(),
        actionFailure: (err: Error) => {
            toast.error<string>(`${t("shared.errors.server-error")} (${err.message})`);

            hideLoadingScreen();
        },
        getFileName: () => {
            if (!data['lagerNumber']) {
                return 'csd.pdf'
            } else {
                return `Order-[${data['lagerNumber']}][CSD].pdf`;
            }
        }
    });

    return (
        <div>
            <Button buttonType={ButtonType.Action}
                    disabled={(data['status'] === null || data['status'] === undefined || data.status !== OrderStatus.Finished)}
                    style={{padding: '0', width: '40px', height: '30px'}}
                    onClick={async (ev) => {
                        ev.stopPropagation();

                        await download();
                    }}>
                <div className={cl.iconButtonContent}>
                    <span><FontAwesomeIcon icon={faFilePdf}/></span>
                </div>
            </Button>
        </div>
    );
};

export default OrderCsdDownloadButtonTemplate;