import {ToolkitStore} from "@reduxjs/toolkit/dist/configureStore";
import instance from "../apiAxios";
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig} from "axios";
import {setIsAuth} from "../../../features/authentication/authenticationSlice";
import {IResponseModel} from "../../types/models/IResponseModel";
import {ResponseResultCode} from "../../types/enums/ResponseResultCode";

const setupInterceptors = (store: ToolkitStore): void => {
    const {dispatch} = store;

    instance.interceptors.request.use(
        (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
            return config;
        },
        (error: AxiosError): Promise<AxiosError> => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (res: AxiosResponse): AxiosResponse => res,
        async (error: AxiosError | Error): Promise<AxiosError> => {
            if (axios.isAxiosError(error)) {
                const originalConfig: AxiosRequestConfig = error.config as AxiosRequestConfig;

                if (error?.response?.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        const response: AxiosResponse<IResponseModel> = await instance.post('api/authentication/refreshToken');

                        if ((response.status === 200 || response.status === 204) && response.data?.resultCode === ResponseResultCode.Ok) {
                            return instance(originalConfig);
                        } else {
                            const response: AxiosResponse = await instance.post('api/authentication/logout');

                            if (response.status === 200 || response.status === 204) {
                                dispatch(setIsAuth(false));
                            }
                        }
                    } catch (error) {
                        return Promise.reject(error);
                    }
                }
            }

            return Promise.reject(error);
        }
    );
}

export default setupInterceptors;