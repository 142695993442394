import React, {useCallback, useState} from "react";
import cl from './OrderGrid.module.css';
import Logo from "../../../components/ui/Logo/Logo";
import OrderGridToolbarSection from "./components/OrderGridToolbarSection/OrderGridToolbarSection";
import {Query} from "@syncfusion/ej2-data";
import {OrderStatus} from "../../../app/types/enums/OrderStatus";
import {ColumnDirective, ColumnsDirective, Page, RowSelectEventArgs} from "@syncfusion/ej2-react-grids";
import GridComponent from "../../../components/ui/GridComponent/GridComponent";
import {Inject} from "@syncfusion/ej2-react-dropdowns";
import EditButtonOrderTemplate from "./templates/EditButtonOrderTemplate/EditButtonOrderTemplate";
import OrderDownloadTemplate from "./templates/OrderDownloadButtonTemplate/OrderDownloadTemplate";
import OrderCsdDownloadButtonTemplate from "./templates/OrderCsdDownloadButtonTemplate/OrderCsdDownloadButtonTemplate";
import OrderStatusTemplate from "./templates/OrderStatusTemplate/OrderStatusTemplate";
import OrderDateTemplate from "./templates/OrderDateTemplate/OrderDateTemplate";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import OrderCsdZipDownloadButtonTemplate
    from "./templates/OrderCsdZipDownloadButtonTemplate/OrderCsdZipDownloadButtonTemplate";

const OrderGrid: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [refreshTrigger, setRefreshTrigger] = useState<number>(0);
    const [query, setQuery] = useState<Query | undefined>();
    const [cancelOrderId, setCancelOrderId] = useState<number | undefined>();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const onSearchChanged = useCallback((text: string) => {
        setQuery(text !== null && text !== undefined && text.length > 0
            ? new Query().addParams('rasco_app_search', text)
            : new Query());
    }, []);

    const rowSelected = useCallback((ev: RowSelectEventArgs) => {
        if (ev?.data) {
            let data = ev.data as any;
            if (data.id > 0 &&
                data.status !== undefined &&
                (data.status === OrderStatus.Avis ||
                    data.status === OrderStatus.Refused ||
                    data.status === OrderStatus.Validated)) {
                setCancelOrderId(data.id);
            }
        }
    }, []);

    const rowDeselected = useCallback(() => {
        setCancelOrderId(undefined);
    }, []);

    const toggleLoadingScreen = useCallback(() => {
        setIsLoading(prev => !prev);
    }, []);

    return (
        <div className={'list-container'}>
            {isLoading &&
                <div className={cl.loadingContainer}>
                    <Logo style={{scale: '0.7'}} animated={true}/>
                </div>
            }
            <OrderGridToolbarSection refreshGrid={() => setRefreshTrigger(prev => prev + 1)}
                                     showLoadingScreen={toggleLoadingScreen}
                                     hideLoadingScreen={toggleLoadingScreen}
                                     cancelOrderId={cancelOrderId}
                                     onSearch={onSearchChanged}/>
            <GridComponent id='grid'
                           container='.list-container'
                           query={query}
                           url='/api/order/getRascoOrders'
                           allowSelection={true}
                           rowSelected={rowSelected}
                           rowDeselected={rowDeselected}
                           refresh={refreshTrigger}>
                <ColumnsDirective>
                    <ColumnDirective field='id'
                                     textAlign={'Center'}
                                     headerText={''}
                                     width={'70'}
                                     headerTextAlign={'Center'}
                                     isPrimaryKey={true}
                                     type='number'
                                     template={(props: any) => <div><EditButtonOrderTemplate data={props}
                                                                                             onClick={(id, status) => {
                                                                                                 navigate(`/order/application/wizard/${id}`,
                                                                                                     status === OrderStatus.Refused
                                                                                                         ? {
                                                                                                             state: {
                                                                                                                 goToStep: 3
                                                                                                             }
                                                                                                         }
                                                                                                         : undefined
                                                                                                 );
                                                                                             }}/>
                                     </div>}/>
                    <ColumnDirective field='lagerNumber'
                                     headerText={t("order.grid.order-number")}
                                     textAlign={'Center'}
                                     headerTextAlign={'Center'}
                                     width='130'/>
                    <ColumnDirective field='id'
                                     textAlign={'Center'}
                                     headerText={t("order.grid.avis")}
                                     width={'80'}
                                     headerTextAlign={'Center'}
                                     isPrimaryKey={true}
                                     type='number'
                                     template={(props: any) => <div><OrderDownloadTemplate data={props}
                                                                                           showLoadingScreen={toggleLoadingScreen}
                                                                                           hideLoadingScreen={toggleLoadingScreen}/>
                                     </div>}/>
                    <ColumnDirective field='id'
                                     textAlign={'Center'}
                                     headerText={t("order.grid.csd")}
                                     width={'80'}
                                     headerTextAlign={'Center'}
                                     isPrimaryKey={true}
                                     type='number'
                                     template={(props: any) => <div><OrderCsdDownloadButtonTemplate data={props}
                                                                                                    showLoadingScreen={toggleLoadingScreen}
                                                                                                    hideLoadingScreen={toggleLoadingScreen}/>
                                     </div>}/>
                    <ColumnDirective field='id'
                                     textAlign={'Center'}
                                     headerText={`${t("order.grid.csd-single")}`}
                                     width={'80'}
                                     headerTextAlign={'Center'}
                                     isPrimaryKey={true}
                                     type={'number'}
                                     template={(props: any) => (
                                         <div>
                                             <OrderCsdZipDownloadButtonTemplate data={props}/>
                                         </div>
                                     )}/>
                    <ColumnDirective field='status'
                                     headerText={t("order.grid.status")}
                                     width='170'
                                     textAlign={'Center'}
                                     headerTextAlign={'Center'}
                                     template={(props: any) => <div style={{height: '35px'}}>
                                         <OrderStatusTemplate {...props} /></div>}/>
                    <ColumnDirective field='orderDate'
                                     headerText={t("order.grid.order-date")}
                                     width='150'
                                     textAlign={'Center'}
                                     headerTextAlign={'Center'}
                                     template={(props: any) => <div><OrderDateTemplate {...props} /></div>}/>
                    <ColumnDirective field='weight'
                                     headerText={t("order.grid.weight")}
                                     textAlign={'Center'}
                                     headerTextAlign={'Center'}
                                     width='150'/>
                    <ColumnDirective field='amount'
                                     headerText={t("order.grid.amount")}
                                     textAlign={'Center'}
                                     headerTextAlign={'Center'}
                                     width='150'/>
                    <ColumnDirective field='contactPerson'
                                     headerText={t("order.grid.contact-person")}
                                     textAlign={'Center'}
                                     headerTextAlign={'Center'}
                                     width='200'/>
                    <ColumnDirective field='plates'
                                     headerText={`${t("order.grid.truck")} / ${t("order.grid.trailer")} / ${t("order.grid.double-trailer")}`}
                                     textAlign={'Center'}
                                     headerTextAlign={'Center'}
                                     width='250'/>
                </ColumnsDirective>
                <Inject services={[Page]}/>
            </GridComponent>
        </div>
    )
};

export default OrderGrid;
