import {ResponseResultCode} from "../enums/ResponseResultCode";

export interface IResponseModel {
    resultCode: ResponseResultCode;
    field?: string;
    description?: string;
}

export const isResponseModel = (item: any): item is IResponseModel => {
    return (item as IResponseModel).resultCode !== undefined;
}