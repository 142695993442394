import React, {useEffect} from 'react';
import cl from './ApplicationOrder.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Button, {ButtonType} from "../../../components/ui/Button/Button";
import document from "../../../assets/images/application_document.png";
import {useAppDispatch, useAppSelector} from "../../../app/store";
import {selectIsHeaderMenuShown} from "../../../features/application/applicationSelectors";
import {setHeaderMenuDisabledState, setHeaderMenuShownState} from "../../../features/application/applicationSlice";

const ApplicationOrder = () => {
    const dispatch = useAppDispatch();
    const isHeaderMenuShown = useAppSelector(selectIsHeaderMenuShown);
    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isHeaderMenuShown) {
            dispatch(setHeaderMenuShownState(true));
            dispatch(setHeaderMenuDisabledState(false));
        }
    }, []);

    return (
        <div className={cl.container}>
            <div className={cl.textContainer}>
                <div>
                    <div>
                        {t("application.start-page.rasco-order")}
                    </div>
                </div>
                <div>
                    <div>
                        {t("application.start-page.new-order")}
                    </div>
                    <Button buttonType={ButtonType.Action}
                            id={'add-new-order-btn'}
                            onClick={() => navigate('/order/application/wizard')}
                            style={{padding: '8px 50px', fontSize: '20px'}}>
                        {t("btn.start")}
                    </Button>
                </div>
            </div>
            <div className={cl.documentContainer}>
                <img src={document} alt='doc'/>
            </div>
        </div>
    );
};

export default ApplicationOrder;