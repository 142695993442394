import React, {useCallback, useState} from "react";
import cl from './RascoFirmGrid.module.css';
import Logo from "../../../components/ui/Logo/Logo";
import RascoFirmGridToolbarSection from "./components/RascoFirmGridToolbarSection/RascoFirmGridToolbarSection";
import {Query} from "@syncfusion/ej2-data";
import {ColumnDirective, ColumnsDirective, Page, RowSelectEventArgs} from "@syncfusion/ej2-react-grids";
import GridComponent from "../../../components/ui/GridComponent/GridComponent";
import {Inject} from "@syncfusion/ej2-react-dropdowns";
import EditButtonRascoFirmTemplate from "./templates/EditButtonRascoFirmTemplate/EditButtonRascoFirmTemplate";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import RascoFirm from "../RascoFirm/RascoFirm";
import {debounce} from "../../../utils/debounce";

const RascoFirmGrid: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [refreshTrigger, setRefreshTrigger] = useState<number>(0);
    const [query, setQuery] = useState<Query | undefined>();
    const [editOrderId, setEditOrderId] = useState<number>();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);

    const onSearchChanged = useCallback((text: string) => {
        setQuery(text !== null && text !== undefined && text.length > 0
            ? new Query().addParams('rasco_app_search', text)
            : new Query());
    }, []);

    const rowSelected = useCallback((ev: RowSelectEventArgs) => {
       /* if (ev?.data) {
            let data = ev.data as any;
            if (data.id > 0 &&
                data.status !== undefined) {
                setEditOrderId(data.id);
            }
        }*/
    }, []);

    const rowDeselected = useCallback(() => {
       // setEditOrderId(undefined);
    }, []);

    const toggleLoadingScreen = useCallback(() => {
        setIsLoading(prev => !prev);
    }, []);

    const onRascoFirmSubmit = useCallback(debounce((): void => {
        setIsEditDialogOpen(false);
    }, 100), []);
    const onRascoFirmCancel = useCallback(debounce((): void => {
        setIsEditDialogOpen(false);

    }, 100), []);
    const onNew = useCallback(debounce((): void => {
        setIsEditDialogOpen(true);

    }, 100), []);

    return (
        <div className={'list-container'}>
            {isLoading &&
                <div className={cl.loadingContainer}>
                    <Logo style={{scale: '0.7'}} animated={true}/>
                </div>
            }
            <RascoFirmGridToolbarSection refreshGrid={() => setRefreshTrigger(prev => prev + 1)}
                                     showLoadingScreen={toggleLoadingScreen}
                                     hideLoadingScreen={toggleLoadingScreen}
                                     editOrderId={editOrderId}
                                         onNew={onNew}
                                     onSearch={onSearchChanged}/>
            { isEditDialogOpen && <RascoFirm editOrderId={editOrderId}  onSubmit={onRascoFirmSubmit} onCancel={onRascoFirmCancel}></RascoFirm>}
            <GridComponent id='grid'
                           container='.list-container'
                           query={query}
                           url='/api/rascofirm/getRascoFirms'
                           allowSelection={true}
                           rowSelected={rowSelected}
                           rowDeselected={rowDeselected}
                           refresh={refreshTrigger}>
                <ColumnsDirective>
                    <ColumnDirective field='id'
                                     textAlign={'Center'}
                                     headerText={''}
                                     width={'70'}
                                     headerTextAlign={'Center'}
                                     isPrimaryKey={true}
                                     type='number'
                                     template={(props: any) => <div><EditButtonRascoFirmTemplate data={props}
                                                                                             onClick={(id) => {

                                                                                                 setTimeout(() => {
                                                                                                     setEditOrderId(id)
                                                                                                     setIsEditDialogOpen(true);
                                                                                                 }, 100);


                                                                                             }}/>
                                     </div>}/>

                    <ColumnDirective field='name'
                                     headerText={t("rascofirm.grid.name")}
                                     textAlign={'Center'}
                                     headerTextAlign={'Center'}
                                     width='130'/>
                    <ColumnDirective field='matchCode'
                                     headerText={t("rascofirm.grid.matchCode")}
                                     textAlign={'Center'}
                                     headerTextAlign={'Center'}
                                     width='130'/>
                    <ColumnDirective field='vatNum'
                                     headerText={t("rascofirm.grid.vatNum")}
                                     textAlign={'Center'}
                                     headerTextAlign={'Center'}
                                     width='130'/>
                    <ColumnDirective field='address'
                                     headerText={t("rascofirm.grid.address")}
                                     textAlign={'Center'}
                                     headerTextAlign={'Center'}
                                     width='130'/>
                    <ColumnDirective field='email'
                                     headerText={t("rascofirm.grid.email")}
                                     textAlign={'Center'}
                                     headerTextAlign={'Center'}
                                     width='130'/>
                </ColumnsDirective>
                <Inject services={[Page]}/>
            </GridComponent>
        </div>
    )
};

export default RascoFirmGrid;
