import React, {ReactNode} from 'react';
import cl from './DialogLayout.module.css';

type DialogLayoutProps = {
    title: string;
    onCloseButtonClick: () => void;
    children: ReactNode;
    disabled?: boolean;
};

const DialogLayout: React.FC<DialogLayoutProps> = ({
                                                       title,
                                                       onCloseButtonClick,
                                                       children,
                                                       disabled
                                                   }) => {
    return (
        <div className={cl.dialogLayout}>
            <div>
                <span>
                    {title}
                </span>
                <span onClick={() => {
                    if (disabled !== undefined && !disabled) {
                        onCloseButtonClick();
                    }
                }}>
                    &times;
                </span>
            </div>
            <div >
                {children}
            </div>
        </div>
    );
};

export default DialogLayout;
