import React from 'react';
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import DownloadOrderCsdZipFileForm from "./DownloadOrderCsdZipFileForm";

export type DownloadCsdZipFileOrder = {
    id: number;
    lagerNumber: string;
};

type DownloadOrderCsdZipFileProps = {
    order: DownloadCsdZipFileOrder;
    opened: boolean;
    setOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const DownloadOrderCsdZipFileDialog: React.FC<DownloadOrderCsdZipFileProps> = ({
                                                                                   opened,
                                                                                   setOpened,
                                                                                   order
                                                                               }) => {
    return (
        <Dialog open={opened}
                width={'700px'}
                height={'300px'}>
            <DownloadOrderCsdZipFileForm close={() => setOpened(false)}
                                         order={order}/>
        </Dialog>
    );
};

export default DownloadOrderCsdZipFileDialog;