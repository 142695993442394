import React, {useCallback, useEffect, useRef, useState} from "react";
import cl from './RascoFirm.module.css';
import Logo from "../../../components/ui/Logo/Logo";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import apiAxios from "../../../app/api/apiAxios";
import {IResponseModel, isResponseModel} from "../../../app/types/models/IResponseModel";
import {toast} from "react-toastify";
import {useAppDispatch} from "../../../app/store";
import {IRascoFirm, rascoFirmApiErrors} from "../../../app/types/models/IRascoFirm";
import {DialogComponent} from "@syncfusion/ej2-react-popups";
import checkbox from 'rc-checkbox';
import {ResponseResultCode} from "../../../app/types/enums/ResponseResultCode";
import Input from "../../../components/ui/Input/Input";
import Checkbox from "../../../components/ui/Checkbox/Checkbox";

type RascoFirmProps = {
    editOrderId?: number;
    onSubmit?: () => void;
    onCancel?: () => void;
};

const RascoFirm: React.FC<RascoFirmProps> = ({
                                                 editOrderId,
                                                 onSubmit, onCancel
                                                                      }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [firmData, setFirmData] = useState<IRascoFirm | null>();
    const dialogInstance = useRef<DialogComponent | null>(null);



    const getData = async () => {

        try {
            const response = await apiAxios.get<IResponseModel | IRascoFirm>(`api/rascofirm/getrascofirm?id=${editOrderId}`);
            if (!isResponseModel(response.data)) {

                let _data = response.data as IRascoFirm;
                if(_data) {
                    setFirmData(response.data);

                }
            }
        } catch (error) {
            if (error instanceof Error) {
                toast.error<string>(`${t("shared.errors.server-error")} (${error.message})`);

            }
        }
        setIsLoading(false);
    }

    useEffect(() => {



        if(editOrderId) {

            getData()



        } else {
            setIsLoading(false);
        }


    }, []);

    function footerTemplate() {
        return (<div>

            <button onClick={saveRascoFirm} id="saveButton" className="e-control e-btn e-primary" data-ripple="true">Save</button>
        </div>);
    }
    function componentCreated() {

    }
    function dialogClose() {
        if(onCancel)
            onCancel()
    }
    async function saveRascoFirm() {

         setIsLoading(true);

         try {
             const response = await apiAxios.post<IResponseModel>(`api/rascofirm/saverascofirm`, firmData);
             if (response?.data !== null &&
                 response?.data.resultCode === ResponseResultCode.Ok) {

                 dialogInstance.current?.hide();
                 if(onSubmit)
                     onSubmit()
             }
         } catch (error) {
             if (error instanceof Error) {
                 toast.error<string>(`${t("shared.errors.server-error")} (${error.message})`);

             }

         }
         setIsLoading(false);

    }

    return (

<div className={'dialogComponent'}>
        <DialogComponent isModal={true} target={'.dialogComponent'} close={dialogClose}  created = {componentCreated} width='350px'  visible={true}  footerTemplate={footerTemplate} showCloseIcon={true}
                             ref={dialogInstance}>
                <div className="dialogContent">
                    {isLoading &&
                        <div className={cl.loadingContainer}>
                            <Logo style={{scale: '0.7'}} animated={true}/>
                        </div>
                    }
                    <div className={cl.formrow}>
                        <label className={cl.label} >{t("rascofirm.form.name")}</label>

                        <input type={'text'} required={true} className={cl.input}
                               onChange={(e) => {
                                   setFirmData({...firmData, name : e.target.value})}
                               }

                               value={firmData?.name}
                        />
                    </div>

                    <div className={cl.formrow}>
                        <label className={cl.label} >{t("rascofirm.form.name")}</label>

                        <input type={'text'} required={true} className={cl.input}
                               onChange={(e) => {
                                   setFirmData({...firmData, matchCode : e.target.value})}
                               }

                               value={firmData?.matchCode}
                        />
                    </div>
                    <div className={cl.formrow}>
                        <label className={cl.label} >{t("rascofirm.form.name")}</label>

                        <input type={'text'} required={true} className={cl.input}
                               onChange={(e) => {
                                   setFirmData({...firmData, vatNum : e.target.value})}
                               }

                               value={firmData?.vatNum}
                        />
                    </div>
                    <div className={cl.formrow}>
                        <label className={cl.label} >{t("rascofirm.form.name")}</label>

                        <input type={'text'} required={true} className={cl.input}
                               onChange={(e) => {
                                   setFirmData({...firmData, contactPerson : e.target.value})}
                               }

                               value={firmData?.contactPerson}
                        />
                    </div>
                    <div className={cl.formrow}>
                        <Checkbox label={t("rascofirm.form.isblocked")}
                               defaultChecked={firmData?.isBlocked}
                               onChange={(e) => {  setFirmData({...firmData, isBlocked : !firmData?.isBlocked})}}
                               checked={firmData?.isBlocked}
                        />
                    </div>
                </div>
            </DialogComponent>
</div>
    )
};

export default RascoFirm;
