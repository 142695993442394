import React, {useEffect, useState} from 'react';
import {Query} from "@syncfusion/ej2-data";
import SyncfusionComboBox from "../../../../../../../components/ui/SyncfusionComboBox/SyncfusionComboBox";
import {useTranslation} from "react-i18next";
import {IRascoApplicationOrderItem} from "../../../../../../../app/types/redux/IRascoApplicationState";
import {updateOrderItem} from "../../../../../../../features/rascoApplication/rascoApplicationSlice";
import {useAppDispatch} from "../../../../../../../app/store";

type OrderItemClientSelectorProps = {
    orderItem: IRascoApplicationOrderItem;
};

const OrderItemClientSelector: React.FC<OrderItemClientSelectorProps> = ({
                                                                               orderItem
                                                                           }) => {
    const dispatch = useAppDispatch();
    const [query, setQuery] = useState<Query | null>(null);
    const {t} = useTranslation();

    useEffect(() => {
        if (orderItem && orderItem.clientId !== null &&
            orderItem.clientId !== undefined) {
            if (orderItem.clientId > 0) {
                setQuery(new Query().addParams('orderItemClientId', orderItem.clientId.toString()));
            } else if (orderItem.clientId === -1 &&
                orderItem.clientId !== null &&
                orderItem.clientId !== undefined) {
                setQuery(new Query().addParams('orderItemClientId', '-1').addParams('name', orderItem.client??""));
            }
        } else {
            setQuery(new Query().addParams('$top', '30'));
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!query) {
        return null
    }

    return (
        <SyncfusionComboBox url={'/api/orderItemClient/getOrderItemClients'}
                            id={`order-item-client-${orderItem.position}`}
                            enabled={true}
                            query={query}
                            placeholder={t("application.cargo-wizard.client")}
                            value={orderItem?.clientId}
                            allowFiltering={true}
                            allowCustom={false}
                            showClearButton={true}
                            fields={{value: 'id', text: 'name'}}
                            select={(ev) => {
                                if (ev.itemData !== null && ev.itemData !== undefined) {
                                    let data = ev.itemData as { id: number | string, name: string, costNotes: string };
                                    if (data && data.id !== undefined && data.name !== undefined && data.id !== null && data.name !== null) {
                                        dispatch(updateOrderItem({
                                            ...orderItem,
                                            clientId: +data.id,
                                            client: data.name,
                                            costNotes: data.costNotes,
                                            updateField: 'client'
                                        }));
                                    }
                                }
                            }}
                            change={(ev) => {
                                if (ev.itemData !== null && ev.itemData !== undefined) {
                                    let data = ev.itemData as { id: number | string, name: string , costNotes: string};
                                    if (data.id !== null && data.name !== null &&
                                        orderItem?.orderItemContentTypeId !== data.id && orderItem?.content !== data.name) {
                                        dispatch(updateOrderItem({
                                            ...orderItem,
                                            clientId: +data.id,
                                            client: data.name,
                                            costNotes: data.costNotes,
                                            updateField: 'client'
                                        }));
                                    }
                                }

                                if ((ev.itemData === undefined || ev.itemData === null) &&
                                    ev.previousItemData !== undefined && ev.previousItemData !== null
                                    && orderItem?.orderItemContentTypeId !== undefined) {
                                    dispatch(updateOrderItem({
                                        ...orderItem,
                                        clientId: undefined,
                                        client: undefined,
                                        cost: undefined,
                                        costNotes: undefined,
                                        updateField: 'client'
                                    }));
                                }
                            }}
                            filtering={(ev) => {
                                setQuery(() => {
                                    if (ev.text === null || ev.text === undefined || ev.text.length < 2) {
                                        return new Query().addParams('$top', '0');
                                    } else {
                                        return new Query().addParams("term", ev.text);
                                    }
                                });
                            }}
                            />
    );
};

export default OrderItemClientSelector;
