import {Language} from "../../../app/types/enums/Language";
import React from "react";
import {useTranslation} from "react-i18next";
import cl from './LanguageSwitcher.module.css';

export enum LanguageSwitcherTheme {
    White = 0,
    Transparent
}

type LanguageSwitcherModel = {
    language: Language;
    logoPath: string;
    label: string;
}

type LanguageSwitcherProps = {
    change?: (lng: Language) => void;
    disabled: boolean;
    theme?: LanguageSwitcherTheme;
    hideText?: boolean;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = React.memo(({
                                                                          disabled,
                                                                          change,
                                                                          theme = LanguageSwitcherTheme.White,
                                                                          hideText = false
                                                                      }) => {
    const {t, i18n} = useTranslation();

    const languages = [
        {language: Language.EN, logoPath: '/images/flags/GB.png', label: t("shared.languages.en")},
        {language: Language.FR, logoPath: '/images/flags/FR.png', label: t("shared.languages.fr")},
        {language: Language.DE, logoPath: '/images/flags/DE.png', label: t("shared.languages.de")}
    ];


    const changeLanguage = (lng: Language) => {
        if (!disabled) {
            if (i18n.language.toLowerCase() !== Language[lng].toString().toLowerCase()) {
                i18n.changeLanguage(Language[lng].toString().toLowerCase())
                    .then(() => {
                        if (change) {
                            change(lng);
                        }
                    });
            }
        }
    }

    return (
        <div className={cl.container} data-theme={LanguageSwitcherTheme[theme].toString().toLowerCase()}>
            {languages.map((item: LanguageSwitcherModel) => {
                return (
                    <div onClick={() => changeLanguage(item.language)}
                         data-disabled={disabled}
                         data-state={i18n.language.toLowerCase() === Language[item.language].toString().toLowerCase() ? 'selected' : null}
                         key={item.language}>
                        <div className={`${cl.img} ${hideText ? cl.hideTextImgContainer : ''}`}>
                            <img src={item.logoPath} alt='logo'/>
                        </div>
                        {!hideText &&
                            <div>
                                {item.label}
                            </div>
                        }
                    </div>
                )
            })}
        </div>
    );
});

export default LanguageSwitcher;