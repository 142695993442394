import React, {ChangeEvent, useCallback, useState} from 'react';
import {useTranslation} from "react-i18next";
import cl from './OrderGridToolbarSection.module.css';
import {debounce} from "../../../../../utils/debounce";
import Input from "../../../../../components/ui/Input/Input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh, faTrash} from "@fortawesome/free-solid-svg-icons";
import ConfirmationDialog, {
    ConfirmationDialogType
} from "../../../../../components/ui/ConfirmationDialog/ConfirmationDialog";
import apiAxios from "../../../../../app/api/apiAxios";
import {IResponseModel} from "../../../../../app/types/models/IResponseModel";
import {IOrder} from "../../../../../app/types/models/IOrder";
import {toast} from "react-toastify";
import {AxiosError} from "axios";
import getServerErrorResourceName from "../../../../../app/apiError";

type OrderGridToolbarSectionProps = {
    refreshGrid: () => void;
    onSearch: (text: string) => void;
    cancelOrderId?: number;
    showLoadingScreen: () => void;
    hideLoadingScreen: () => void;
};

const OrderGridToolbarSection: React.FC<OrderGridToolbarSectionProps> = React.memo(({
                                                                                        refreshGrid,
                                                                                        cancelOrderId,
                                                                                        onSearch,
                                                                                        hideLoadingScreen,
                                                                                        showLoadingScreen
                                                                                    }) => {
    const {t} = useTranslation();
    const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');

    const onSearchChanged = useCallback(debounce((ev: ChangeEvent<HTMLInputElement>): void => {
        setSearch(ev.target.value);
        onSearch(ev.target.value);
    }, 1000), []);

    const cancelOrder = () => {
        (async () => {
            setIsCancelDialogOpen(false);
            showLoadingScreen();

            try {
                const response = await apiAxios.post<IResponseModel>('api/order/cancel', {
                    id: cancelOrderId,
                    utcOffset: (new Date()).getTimezoneOffset()
                } as IOrder);

                if (response !== null && response.status === 200) {
                    toast.success<string>(t("order.grid.cancel-success-label"));

                    refreshGrid();
                }
            } catch (error) {
                if (error instanceof AxiosError && error.response !== undefined && error.response.data !== null) {
                    if (error.response.data.description !== null && error.response.data.description !== undefined) {
                        toast.error<string>(t(getServerErrorResourceName('order', error.response.data.resultCode, error.response.data.field, error.response.data.description)))
                    } else {
                        toast.error<string>(t(getServerErrorResourceName('order', error.response.data.resultCode, error.response.data.field)))
                    }
                } else if (error instanceof Error) {
                    toast.error<string>(`${t("shared.errors.server-error")} (${error.message})`);
                }
            }

            hideLoadingScreen();
        })();
    };

    return (
        <>
            <ConfirmationDialog type={ConfirmationDialogType.Alert}
                                onCancel={() => setIsCancelDialogOpen(prev => !prev)}
                                onSubmit={cancelOrder}
                                open={isCancelDialogOpen}
                                title={t("order.grid.cancel-order-dialog-label")}
                                text={t("order.grid.cancel-order-dialog-text")}/>
            <div className={cl.btnContainer}>
                <Input type={'search'}
                       value={search}
                       onChange={onSearchChanged}
                       placeholder={t("order.grid.search")}/>
                <div>
                    {cancelOrderId !== undefined && cancelOrderId > 0
                        ? <button className={`${cl.button} ${cl.buttonIconPadding}`}
                                  style={{backgroundColor: 'red', marginRight: '10px'}}
                                  onClick={() => setIsCancelDialogOpen(prev => !prev)}>
                            <div className={cl.icon}>
                                <FontAwesomeIcon icon={faTrash}/>
                            </div>
                            {t("order.grid.cancel-label")}
                        </button>
                        : null
                    }
                    <button className={`${cl.button} ${cl.buttonIconPadding}`}
                            onClick={() => refreshGrid()}>
                        <div className={cl.icon}>
                            <FontAwesomeIcon icon={faRefresh}/>
                        </div>
                        {t("btn.refresh")}
                    </button>
                </div>
            </div>
        </>
    );
});

export default OrderGridToolbarSection;