import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    IRascoApplicationWizardState,
    IWizardProgressBarStep,
    UpdateWizardProgressBarStep
} from "../../app/types/redux/IRascoApplicationWizardState";

const initialState: IRascoApplicationWizardState = {
    showCancelButton: true,
    showNextButton: true,
    showPreviousButton: true,
    isCancelButtonDisabled: false,
    isNextButtonDisabled: false,
    isPreviousButtonDisabled: false,
    showProgressBar: true,
    showFooter: true,
    progressBarItems: [],
    cancelButtonAlign: 'left',
    nextButtonAlign: 'right',
    previousButtonAlign: 'right'
}

const rascoApplicationWizardSlice = createSlice({
    name: 'rascoApplicationWizard',
    initialState,
    reducers: {
        setRascoApplicationWizardState: (state, action: PayloadAction<IRascoApplicationWizardState>) => {
            state.isCancelButtonDisabled = action.payload.isCancelButtonDisabled;
            state.showCancelButton = action.payload.showCancelButton;

            state.isNextButtonDisabled = action.payload.isNextButtonDisabled;
            state.showNextButton = action.payload.showNextButton;

            state.isPreviousButtonDisabled = action.payload.isPreviousButtonDisabled;
            state.showPreviousButton = action.payload.showPreviousButton;

            state.showProgressBar = action.payload.showProgressBar;
            state.showFooter = action.payload.showFooter;

            state.nextButtonAlign = action.payload.nextButtonAlign;
            state.previousButtonAlign = action.payload.previousButtonAlign;
            state.cancelButtonAlign = action.payload.cancelButtonAlign;
        },
        setRascoApplicationWizardProgressBarState: (state, action: PayloadAction<boolean>) => {
            state.showProgressBar = action.payload;
        },
        setProgressBarItems: (state, action: PayloadAction<Array<IWizardProgressBarStep>>) => {
            state.progressBarItems = action.payload;
        },
        setFooterState: (state, action: PayloadAction<boolean>) => {
            state.showFooter = action.payload
        },
        updateProgressBarItem: (state, action: PayloadAction<UpdateWizardProgressBarStep>) => {
            if (state.progressBarItems) {
                const existItem = state.progressBarItems.find((item: IWizardProgressBarStep) => {
                    return item.orderStep === action.payload.orderStep;
                });

                if (existItem) {
                    existItem.status = action.payload.status;
                }
            }
        }
    }
});

export const {
    setRascoApplicationWizardState,
    updateProgressBarItem,
    setRascoApplicationWizardProgressBarState,
    setProgressBarItems,
    setFooterState
} = rascoApplicationWizardSlice.actions;

export default rascoApplicationWizardSlice.reducer;