import React from "react";
import {useTranslation} from "react-i18next";
import {OrderStatus} from "../../../../../app/types/enums/OrderStatus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";

type EditButtonOrderTemplateProps = {
    onClick: (id: number, status: OrderStatus) => void;
    data: any;
}

const activeStyle: React.CSSProperties = {
    fontSize: '20px',
    cursor: 'pointer',
    color: "lightgreen"
};

const disabledStyle: React.CSSProperties = {
    fontSize: '20px',
    cursor: 'not-allowed',
    color: 'gray'
};

const EditButtonRascoFirmTemplate: React.FC<EditButtonOrderTemplateProps> = ({
                                                                             onClick,
                                                                             data
                                                                         }) => {
    const {t} = useTranslation();

    return (
        <div>
            <FontAwesomeIcon style={activeStyle}
                             onClick={() => {
                                 onClick(data.id, data.status);

                             }}
                             title={t("rascofirm.grid.edit-label")}
                             icon={faPen}/>
        </div>
    );
};

export default EditButtonRascoFirmTemplate;
