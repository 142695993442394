import React, {useState} from 'react';
import {IWizard, IWizardProps} from "./utils/wizardTypes";
import WizardContext from "./utils/wizardContext";
import cl from './Wizard.module.css';
import Logo from "../Logo/Logo";

const Wizard: React.FC<React.PropsWithChildren<IWizardProps>> = React.memo((
    {
        header,
        footer,
        children,
        wrapper
    }) => {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [stepCount] = useState<number>(React.Children.toArray(children).length);
    const hasNextStep = React.useRef(true);
    const hasPreviousStep = React.useRef(false);
    const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(false);

    hasNextStep.current = activeStep < stepCount - 1;
    hasPreviousStep.current = activeStep > 0;

    const goToNextStep = React.useRef((): void => {
        if (hasNextStep.current) {
            setActiveStep(step => step + 1);
        }
    });

    const goToPreviousStep = React.useRef((): void => {
        if (hasPreviousStep.current) {
            setActiveStep(step => step - 1);
        }
    });

    const goToStep = React.useRef((step: number): void => {
        if (step <= stepCount && step >= 0) {
            setActiveStep(step);
        }
    });

    const showLoadingScreen = React.useRef((): void => {
        setIsLoadingScreen(true);
    });

    const hideLoadingScreen = React.useRef((): void => {
        setIsLoadingScreen(false);
    });

    const value = React.useMemo(() => ({
        goToNextStep: goToNextStep.current,
        goToPreviousStep: goToPreviousStep.current,
        goToStep: goToStep.current,
        activeStep: activeStep,
        stepCount: stepCount,
        showLoadingScreen: showLoadingScreen.current,
        hideLoadingScreen: hideLoadingScreen.current
    } as IWizard), [activeStep, stepCount]);

    const activeStepContent = React.useMemo(() => {
        const steps = React.Children.toArray(children);

        return steps[activeStep];
    }, [activeStep, children, header, footer])

    const wrappedActiveStepContent = React.useMemo(
        () =>
            wrapper
                ? React.cloneElement(wrapper, {children: activeStepContent})
                : activeStepContent,
        [wrapper, activeStepContent],
    );

    return (
        <WizardContext.Provider value={value}>
            <div className={cl.container}>
                {isLoadingScreen
                    ? <div className={cl.loadingScreenContainer}><Logo style={{transform: 'scale(0.7)'}} animated={true}/>
                    </div>
                    : null
                }
                {header}
                {wrappedActiveStepContent}
                {footer}
            </div>
        </WizardContext.Provider>
    );
});

export default Wizard;