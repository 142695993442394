import React from 'react';
import cl from './RelativeLoadingScreen.module.css';
import Logo from "../Logo/Logo";

const RelativeLoadingScreen: React.FC = () => {
    return (
        <div className={cl.container}>
            <Logo animated={true}/>
        </div>
    );
};

export default RelativeLoadingScreen;