import {useCallback, useEffect, useState} from "react";
import {getApplicationWindow, injectScript, removeScript} from "../helpers/htmlDom/htmlDom";

const GOOGLE_RECAPTCHA_V3_SCRIPT = 'https://www.google.com/recaptcha/api.js';
const GOOGLE_RECAPTCHA_BADGE_CLASSNAME = '.grecaptcha-badge';
const GOOGLE_RECAPTCHA_SCRIPT_ID = 'google-recaptcha-v3';

interface IGoogleReCaptchaOptions {
    siteKey: string;
    language?: string;
}

interface IGoogleReCaptchaV3HookReturn {
    executeReCaptcha: (action?: string) => Promise<string>;
    loaded: boolean;
}

type UseGoogleReCaptchaV3Props = (options: IGoogleReCaptchaOptions) => IGoogleReCaptchaV3HookReturn;

const useGoogleReCaptchaV3: UseGoogleReCaptchaV3Props = ({
                                                             siteKey,
                                                             language
                                                         }) => {
    const [loaded, setLoaded] = useState<boolean>(false);

    const executeReCaptcha = useCallback(
        async (action?: string): Promise<string> => {
            const window = getApplicationWindow();
            if (!window) {
                throw new Error('Window not found');
            }

            const {grecaptcha} = window;
            if (!grecaptcha || !loaded) {
                throw new Error('Script not loaded or available');
            }

            return new Promise(resolve => {
                grecaptcha.ready(() => {
                    grecaptcha.execute(siteKey, action ? {action} : undefined)
                        .then(token => resolve(token));
                });
            });
        },
        [siteKey, loaded]
    );

    const removeGoogleReCaptchaBadgeElement = (): void => {
        const window = getApplicationWindow();
        if (!window) {
            return;
        }

        const badge = window.document.querySelector(GOOGLE_RECAPTCHA_BADGE_CLASSNAME);
        if (badge && badge.parentElement) {
            badge.remove();
        }
    }

    useEffect(() => {
        if (!siteKey) {
            return;
        }

        const window = getApplicationWindow();

        if (!window) {
            return;
        }

        const scriptElement = window.document.getElementById(GOOGLE_RECAPTCHA_SCRIPT_ID);

        if (!scriptElement) {
            injectScript(GOOGLE_RECAPTCHA_SCRIPT_ID,
                `${GOOGLE_RECAPTCHA_V3_SCRIPT}?render=${siteKey}${language ? `&hl=${language}` : ''}`,
                () => setLoaded(true));
        }

        return () => {
            removeScript(GOOGLE_RECAPTCHA_SCRIPT_ID);
            removeGoogleReCaptchaBadgeElement();
        }
    }, [siteKey, language])

    return {executeReCaptcha, loaded};
};

export default useGoogleReCaptchaV3;