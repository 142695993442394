import {ReactNode} from "react";
import BaseLayout from "../../components/layouts/BaseLayout/BaseLayout";
import AnonymousLayout from "../../components/layouts/AnonymousLayout/AnonymousLayout";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import AuthorizedLayout from "../../components/layouts/AuthorizedLayout/AuthorizedLayout";
import {Navigate} from "react-router-dom";
import Login from "../../pages/anonymous/Login/Login";
import ApplicationOrder from "../../pages/authorized/ApplicationOrder/ApplicationOrder";
import ApplicationOrderWizard from "../../pages/authorized/ApplicationOrderWizard/ApplicationOrderWizard";
import OrderGrid from "../../pages/authorized/OrderGrid/OrderGrid";
import RascoFirmGrid from "../../pages/authorized/RascoFirmGrid/RascoFirmGrid";
import RascoFirm from "../../pages/authorized/RascoFirm/RascoFirm";

interface IRoute {
    element: ReactNode;
    path?: string;
    errorElement?: ReactNode;
    children?: Array<IRoute>;
}

const routes: Array<IRoute> = [
    {
        element: <BaseLayout/>,
        errorElement: <Navigate to={'/order/application'} replace/>,
        children: [
            {
                element:
                    (
                        <PrivateRoute>
                            <AuthorizedLayout/>
                        </PrivateRoute>
                    ),
                children: [
                    {
                        path: '/orders',
                        element: <OrderGrid />
                    },
                    {
                        path: '/rascofirms',
                        element: <RascoFirmGrid />
                    },
                    {
                        path: '/company',
                        element: <div>Company</div>
                    },
                    {
                        path: '/order/application',
                        element: <ApplicationOrder/>
                    },
                    {
                        path: '/order/application/wizard/:id?',
                        element: <ApplicationOrderWizard/>
                    }
                ]
            },
            {
                element: <AnonymousLayout/>,
                children: [
                    {
                        path: '/login',
                        element: <Login/>
                    }
                ]
            }
        ]
    }
];

export default routes;
