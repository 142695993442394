import {RootState} from "../../app/store/store";
import {createSelector} from "@reduxjs/toolkit";
import {IRascoApplicationWizardState} from "../../app/types/redux/IRascoApplicationWizardState";

export const selectRascoApplicationWizardState = createSelector(
    (state: RootState): IRascoApplicationWizardState => state.rascoApplicationWizard,
    (wizardState: IRascoApplicationWizardState): IRascoApplicationWizardState => ({
        isPreviousButtonDisabled: wizardState.isPreviousButtonDisabled,
        showPreviousButton: wizardState.showPreviousButton,
        isNextButtonDisabled: wizardState.isNextButtonDisabled,
        showNextButton: wizardState.showNextButton,
        isCancelButtonDisabled: wizardState.isCancelButtonDisabled,
        showCancelButton: wizardState.showCancelButton,
        showProgressBar: wizardState.showProgressBar,
        progressBarItems: wizardState.progressBarItems,
        showFooter: wizardState.showFooter,
        nextButtonAlign: wizardState.nextButtonAlign,
        previousButtonAlign: wizardState.previousButtonAlign,
        cancelButtonAlign: wizardState.cancelButtonAlign
    } as IRascoApplicationWizardState)
);
