import React, {useEffect, useState} from 'react';
import {DownloadCsdZipFileOrder} from "./DownloadOrderCsdZipFileDialog";
import cl from './DownloadOrderCsdZipFileForm.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import apiAxios from "../../../../../app/api/apiAxios";
import {IResponseModel, isResponseModel} from "../../../../../app/types/models/IResponseModel";
import {toast} from "react-toastify";
import {IOrder} from "../../../../../app/types/models/IOrder";
import Logo from "../../../../../components/ui/Logo/Logo";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {IOrderItem} from "../../../../../app/types/models/IOrderItem";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    RowDeselectEventArgs,
    RowSelectEventArgs
} from '@syncfusion/ej2-react-grids';
import {isAxiosError} from "axios";
import JSZip from "jszip";

type DownloadOrderCsdZipFileFormProps = {
    order: DownloadCsdZipFileOrder;
    close: () => void;
};

const DownloadOrderCsdZipFileForm: React.FC<DownloadOrderCsdZipFileFormProps> = ({
                                                                                     order,
                                                                                     close
                                                                                 }) => {
    const {t} = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadedOrder, setLoadedOrder] = useState<IOrder | null>(null);

    const [selectedOrderItems, setSelectedOrderItems] = useState<Array<IOrderItem>>([]);

    useEffect(() => {
        (async () => {
            await loadOrder(order.id);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadOrder = async (id: number) => {
        setIsLoading(prev => !prev);

        try {
            const response = await apiAxios.get<IResponseModel | IOrder>(`api/order/getOrder?id=${id}`);
            if (!isResponseModel(response.data)) {
                setLoadedOrder(response.data);

                if (response.data.orderItems.length <= 0) {
                    setTimeout(() => {
                        close();
                    }, 1000);
                }
            }
        } catch (error) {
            if (error instanceof Error) {
                toast.error<string>(`${t("shared.errors.server-error")} (${error.message})`);

                setTimeout(() => {
                    close();
                }, 1000);
            }
        }

        setIsLoading(prev => !prev);
    };

    const getFileUrl = (): string => {
        let uri = `/api/order/downloadRascoOrderCsdZipFile?orderId=${order.id}&utcOffset=${(new Date()).getTimezoneOffset()}`;

        if (selectedOrderItems.length > 0) {
            uri += `&orderItemIds=${selectedOrderItems.map(item => item.id).join(';')}`;
        }

        return uri;
    };

    const downloadFiles = async () => {
        try {
            setIsLoading(prev => !prev);

            const {data} = await apiAxios.get(getFileUrl(), {
                responseType: 'blob'
            });

            const zip = await JSZip.loadAsync(new Blob([data]));

            const filePromises: Array<Promise<{
                name: string;
                data: Blob;
            }>> = [];

            zip.forEach((relativePath, zipEntry) => {
                filePromises.push(
                    zipEntry.async('blob').then((fileData) => ({
                        name: relativePath,
                        data: fileData,
                    }))
                );
            });

            const files = await Promise.all(filePromises);

            for (let file of files) {
                const url = URL.createObjectURL(file.data);
                const elem = document.createElement('a');

                elem.href = url;

                elem.setAttribute('download', `${order.lagerNumber}-${file.name}`);

                document.body.appendChild(elem);

                elem.click();

                URL.revokeObjectURL(url);
                document.body.removeChild(elem);
            }

            setIsLoading(prev => !prev);
            close();
        } catch (err) {
            setIsLoading(false);
            if (isAxiosError(err)) {
                toast.error<string>(`${t("shared.errors.server-error")} (${err.message})`);
            }
        }
    };

    return (
        <div className={cl.container}>
            <header className={cl.header}>
                <div>
                    {`${t("order.actions.order-details").replace('{0}', order.lagerNumber)}`}
                </div>

                <div>
                    <FontAwesomeIcon icon={faXmark}
                                     className={`${cl.close}`}
                                     onClick={() => {
                                         if (isLoading) {
                                             return;
                                         }

                                         close();
                                     }}/>
                </div>
            </header>

            <main className={cl.main}>
                {isLoading &&
                    <div className={cl.loadingContainer}>
                        <Logo animated={true} style={{scale: '0.7'}}/>
                    </div>
                }

                {!isLoading && loadedOrder &&
                    <div className={'oi-list-container'}>
                        <GridComponent dataSource={loadedOrder.orderItems}
                                       width={'100%'}
                                       height={'100%'}
                                       rowSelected={(ev: RowSelectEventArgs) => {
                                           if (ev.data) {
                                               let orderItemsToSelect = Array.isArray(ev.data)
                                                   ? ev.data
                                                   : [ev.data];
                                               let res: Array<IOrderItem> = []

                                               orderItemsToSelect.forEach(oi => {
                                                   let existItem = selectedOrderItems.find(e => e.id === oi.id);

                                                   if (!existItem) {
                                                       res.push({...oi});
                                                   }
                                               });

                                               setSelectedOrderItems([
                                                   ...selectedOrderItems,
                                                   ...res
                                               ]);
                                           }
                                       }}
                                       rowDeselected={(ev: RowDeselectEventArgs) => {
                                           if (ev.data) {
                                               let orderItemsToDeselect = Array.isArray(ev.data)
                                                   ? ev.data
                                                   : [ev.data];

                                               let res: Array<IOrderItem> = [];

                                               selectedOrderItems.forEach(soi => {
                                                   let itemToDelete = orderItemsToDeselect.find(e => e.id === soi.id);

                                                   if (!itemToDelete) {
                                                       res.push(soi);
                                                   }
                                               });

                                               setSelectedOrderItems([...res]);
                                           }
                                       }}
                                       selectionSettings={{
                                           mode: 'Row',
                                           type: 'Multiple'
                                       }}>
                            <ColumnsDirective>
                                <ColumnDirective type='checkbox'
                                                 width={'50'}
                                                 textAlign={'Center'}
                                                 headerTextAlign={'Center'}
                                                 allowSorting={false}/>

                                <ColumnDirective field={'shipmentIdentificationNumber'}
                                                 headerText={t("application.cargo-wizard.shipment-number")}
                                                 textAlign={'Center'}
                                                 headerTextAlign={'Center'}
                                                 width='100%'
                                                 allowSorting={false}/>

                                <ColumnDirective field={'amount'}
                                                 headerText={t("application.cargo-wizard.amount")}
                                                 textAlign={'Center'}
                                                 headerTextAlign={'Center'}
                                                 width='115'
                                                 allowSorting={false}/>

                                <ColumnDirective field={'weight'}
                                                 headerText={t("application.cargo-wizard.weight")}
                                                 textAlign={'Center'}
                                                 headerTextAlign={'Center'}
                                                 width='115'
                                                 allowSorting={false}/>
                            </ColumnsDirective>
                        </GridComponent>
                    </div>
                }
            </main>

            <footer className={cl.footer}>
                <Button buttonType={ButtonType.Primary}
                        disabled={isLoading || selectedOrderItems.length <= 0}
                        onClick={async () => {
                            if (isLoading || selectedOrderItems.length <= 0) {
                                return;
                            }

                            await downloadFiles();
                        }}>
                    {t("application.submit-wizard.download-btn")}
                </Button>
            </footer>
        </div>
    );
};

export default DownloadOrderCsdZipFileForm;
