import React, {ReactNode} from 'react';
import cl from './Dialog.module.css';

type DialogProps = {
    open: boolean;
    children: ReactNode;
    width?: string;
    height?: string;
    onOverlayClick?: () => void;
};

const Dialog: React.FC<DialogProps> = ({
                                           open,
                                           children,
                                           width,
                                           height,
                                           onOverlayClick
                                       }) => {

    if (!open) {
        return null;
    }

    const onOverlay = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = ev.target as HTMLDivElement;
        if (target && target.getAttribute('data-type') === 'overlay' && onOverlayClick) {
            onOverlayClick();
        }
    }

    const getStyle = (): React.CSSProperties | undefined => {
        const object = {} as React.CSSProperties;

        if (width) {
            object.width = width;
        }

        if (height) {
            object.height = height;
        }

        return object;
    }

    return (
        <div className={cl.overlay}
             onClick={onOverlay}
             data-type='overlay'>
            <div className={`${cl.dialog} ${cl.bounce}`} style={getStyle()}>
                {children}
            </div>
        </div>
    );
};

export default Dialog;