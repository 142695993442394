import React, {ChangeEvent, useCallback, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import cl from './RascoFirmGridToolbarSection.module.css';
import {debounce} from "../../../../../utils/debounce";
import Input from "../../../../../components/ui/Input/Input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faRefresh, faTrash} from "@fortawesome/free-solid-svg-icons";
import ConfirmationDialog, {
    ConfirmationDialogType
} from "../../../../../components/ui/ConfirmationDialog/ConfirmationDialog";
import apiAxios from "../../../../../app/api/apiAxios";
import {IResponseModel} from "../../../../../app/types/models/IResponseModel";
import {IOrder} from "../../../../../app/types/models/IOrder";
import {toast} from "react-toastify";
import {AxiosError} from "axios";
import getServerErrorResourceName from "../../../../../app/apiError";
import RascoFirm from "../../../RascoFirm/RascoFirm";
import {DialogComponent} from "@syncfusion/ej2-react-popups";

type RascoFirmGridToolbarSectionProps = {
    refreshGrid: () => void;
    onSearch: (text: string) => void;
    editOrderId?: number;
    showLoadingScreen: () => void;
    hideLoadingScreen: () => void;
    onNew: () => void;
};

const RascoFirmGridToolbarSection: React.FC<RascoFirmGridToolbarSectionProps> = React.memo(({
                                                                                        refreshGrid, editOrderId,
                                                                                        onSearch,
                                                                                        hideLoadingScreen,
                                                                                        showLoadingScreen,
                                                                                                onNew
                                                                                    }) => {
    const {t} = useTranslation();

    const [search, setSearch] = useState<string>('');


    const onSearchChanged = useCallback(debounce((ev: ChangeEvent<HTMLInputElement>): void => {
        setSearch(ev.target.value);
        onSearch(ev.target.value);
    }, 1000), []);


    return (
        <>
            <div className={cl.btnContainer}>
                <Input type={'search'}
                       value={search}
                       onChange={onSearchChanged}
                       placeholder={t("order.grid.search")}/>
                <div>
                    {/*{editOrderId !== undefined && editOrderId > 0*/}
                    {/*    ? <button className={`${cl.button} ${cl.buttonIconPadding}`}*/}
                    {/*              style={{backgroundColor: 'red', marginRight: '10px'}}*/}
                    {/*              onClick={() => setIsCancelDialogOpen(prev => !prev)}>*/}
                    {/*        <div className={cl.icon}>*/}
                    {/*            <FontAwesomeIcon icon={faTrash}/>*/}
                    {/*        </div>*/}
                    {/*        {t("order.grid.cancel-label")}*/}
                    {/*    </button>*/}
                    {/*    : null*/}
                    {/*}*/}
                    <button className={`${cl.button} ${cl.buttonIconPadding}`}
                            style={{ marginRight: '10px'}}
                            onClick={() => {

                                onNew()

                            }}>
                        <div className={cl.icon}>
                            <FontAwesomeIcon icon={faPlus}/>
                        </div>
                        {t("rascofirm.grid.add-label")}
                    </button>
                    <button className={`${cl.button} ${cl.buttonIconPadding}`}
                            onClick={() => refreshGrid()}>
                        <div className={cl.icon}>
                            <FontAwesomeIcon icon={faRefresh}/>
                        </div>
                        {t("btn.refresh")}
                    </button>
                </div>
            </div>
        </>
    );
});

export default RascoFirmGridToolbarSection;
