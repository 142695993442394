import {IApplicationWindow} from "../../app/types/app/IApplicationWindow";

export const getApplicationWindow = () => typeof (window) !== "undefined" ? window as unknown as IApplicationWindow : undefined;

export const injectScript = (id: string, src: string, onLoad: () => void): boolean => {
    const window = getApplicationWindow();

    if (!window) {
        return false;
    }

    const head = window.document.getElementsByTagName('head')[0];

    if (head) {
        const scriptTag = window.document.createElement('script');
        scriptTag.id = id;
        scriptTag.src = src;

        if (onLoad) scriptTag.onload = onLoad;

        if ('async' in scriptTag) scriptTag.async = true;

        if ('defer' in scriptTag) scriptTag.defer = true;

        head.appendChild(scriptTag);

        return true;
    }

    return false;
}

export const removeScript = (id: string): boolean => {
    const window = getApplicationWindow();

    if (!window) {
        return false;
    }

    const scriptTag = window.document.getElementById(id);

    if (scriptTag) {
        scriptTag.remove();

        return true;
    }

    return false;
}