import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import cl from './OrderItemChooser.module.css';
import {useTranslation} from "react-i18next";
import {ChangedEventArgs, DatePickerComponent} from "@syncfusion/ej2-react-calendars";

import SearchInput from "../../../../../../../components/ui/SearchInput/SearchInput";
import {IOrderItem} from "../../../../../../../app/types/models/IOrderItem";
import apiAxios from "../../../../../../../app/api/apiAxios";
import {toast} from "react-toastify";
import moment from "moment/moment";
import {IOrderItemForAvis} from "../../../../../../../app/types/models/IOrderItemForAvis";
import OrderItemChooserItem from "./OrderItemChooserItem/OrderItemChooseItem";
import {forEach} from "react-bootstrap/ElementChildren";
import {IRascoApplicationOrderItem} from "../../../../../../../app/types/redux/IRascoApplicationState";
import uuid from "react-uuid";
import Input from "../../../../../../../components/ui/Input/Input";


type OrderItemChooserProps = {
    currentItems: IRascoApplicationOrderItem[] | undefined,
    onItemAdded: (ev: IRascoApplicationOrderItem) => void;
};

enum SearchPeriod  {
    All,
    Today,
    Yesterday,
    SelectedDate
};

const OrderItemChooser: React.FC<OrderItemChooserProps> = ({
                                                               currentItems,
                                                               onItemAdded
                                                                         }) => {

    const [selectedDate, setSelectedDate] = useState<Date | undefined>(new Date());
    const [selectedPeriod, setSelectedPeriod] = useState<SearchPeriod>(SearchPeriod.All);
    const [filter, setFilter] = useState<string>('');
    const [orderItems, setOrderItems] = useState<Array<IOrderItemForAvis>>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {t} = useTranslation();
    const listContainerRef = useRef<HTMLDivElement>(null)
    const [listHeight, setListHeight] = useState<number>(0);

    const getData = async () => {

        try {
            const response = await apiAxios.get<Array<IOrderItemForAvis>>(`api/orderitemclient/getOrderItemsForAvis?period=${selectedPeriod}&date=${moment(selectedDate).format("YYYY-MM-DD")}&filter=${filter}`);

            if(response.data) {

                let resultItems = response.data;

                if(currentItems) {
                    for (let item of resultItems) {
                        if (currentItems.filter(e => e.avisItemId == item.id).length > 0) {

                            response.data = response.data.filter(e => e.id != item.id);
                        }
                    }
                }

                setOrderItems(response.data);

            }
        } catch (error) {
            if (error instanceof Error) {
                toast.error<string>(`${t("shared.errors.server-error")} (${error.message})`);

            }
        }
        setIsLoading(false);
    }

    function onItemSelected(ev: IOrderItemForAvis) {

        if(orderItems) {
            for (let item of orderItems) {
                if (item.id ==ev.id) {
                    item.isSelected  = true;
                }
            }
            setOrderItems([...orderItems]);

            onItemAdded({
                avisItemId: ev.id,
                uuid: uuid(),
                position: currentItems === undefined || currentItems.length <= 0
                    ? 1
                    : Math.max(...currentItems.map(item => item.position)) + 1,
                shipmentIdentificationNumber: ev.identificationNumber ? ev.identificationNumber : undefined,
                amount: ev.amount ? ev.amount : undefined,
                weight: ev.weight ? ev.weight : undefined,
                content: ev.content ? ev.content : undefined,
                orderItemContentTypeId: ev.contentId? ev.contentId : undefined,
                clientId:ev.customerId,
                client:ev.customer,
                cost:'',
                costNotes:ev.costNotes,
            })
        }
    }

    useEffect(() => {
        getData()
    }, [filter, selectedPeriod, selectedDate]);

    useEffect(() => {
        if(listContainerRef && listContainerRef.current) {

            const { height } = listContainerRef.current.getBoundingClientRect();

            setListHeight(height)
        }
    })
    useLayoutEffect(() => {



    }, []);
    return(
    <div className={cl.container} >
    <div style={{marginBottom:20}}>
        <Input type={'search'} placeholder={t("order.grid.search")} onChange={(e) => {setFilter(e.target.value)}}></Input>
    </div>
    <div style={{marginBottom:20}} className={cl.search_period_container}>
        <div className={`${cl.search_period} ${selectedPeriod == SearchPeriod.All ? cl.selected_search_period : null}`} onClick={e=>setSelectedPeriod(SearchPeriod.All)}>{t("application.period.all")}</div>
        <div className={`${cl.search_period} ${selectedPeriod == SearchPeriod.Today ? cl.selected_search_period : null}`} onClick={e=>setSelectedPeriod(SearchPeriod.Today)}>{t("application.period.today")}</div>
        <div className={`${cl.search_period} ${selectedPeriod == SearchPeriod.Yesterday ? cl.selected_search_period : null}`} onClick={e=>setSelectedPeriod(SearchPeriod.Yesterday)}>{t("application.period.yesterday")}</div>
        <div className={`${cl.search_period} ${selectedPeriod == SearchPeriod.SelectedDate? cl.selected_search_period : null}`} onClick={e=>setSelectedPeriod(SearchPeriod.SelectedDate)}><DatePickerComponent value={selectedDate}
                                                                                                                                                                                                               change={(ev: ChangedEventArgs) => {
                                                                                                                                                                                                                   setSelectedDate(ev.value)
                                                                                                                                                                                                               }} format='dd.MM.yyyy' ></DatePickerComponent></div>
    </div>
        <div style={{height:'100%'}} ref={listContainerRef} >
        <div style={{ height: listHeight, overflowY: 'scroll' }}>
        {orderItems?.filter(e => !e.isSelected).map(function(data) {
            return (
                <OrderItemChooserItem onSelected={onItemSelected} data={data} />
            )
        })}
        </div>
        </div>
    </div>);
}

export default OrderItemChooser;
