import React from 'react';
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import useDownloadFile from "../../../../../hooks/useDownloadFile";
import {toast} from "react-toastify";
import cl from './OrderDownloadTemplate.module.css';
import {useTranslation} from "react-i18next";
import moment from "moment/moment";

type OrderDownloadTemplateProps = {
    showLoadingScreen: () => void;
    hideLoadingScreen: () => void;
    data: any;
}

const OrderDownloadTemplate: React.FC<OrderDownloadTemplateProps> = ({
                                                                         showLoadingScreen,
                                                                         hideLoadingScreen,
                                                                         data
                                                                     }) => {
    const {t} = useTranslation();
    const getFileUrl = (): string => `/api/rascoApplication/downloadSecuritizationRequestDocument?orderId=${data.id}&utcOffset=${(new Date()).getTimezoneOffset()}`;

    const {download} = useDownloadFile({
        apiUrl: getFileUrl(),
        beforeDownload: () => showLoadingScreen(),
        afterDownload: () => hideLoadingScreen(),
        actionFailure: (err: Error) => {
            toast.error<string>(`${t("shared.errors.server-error")} (${err.message})`);

            hideLoadingScreen();
        },
        getFileName: () => {
            if (!data['orderDate'] || !data['lagerNumber']) {
                return 'avis.pdf'
            } else {
                return `${data['lagerNumber']}-avis-${moment(data['orderDate'] + '+00:00').local().format('DD-MM-YYYY')}.pdf`;
            }
        }
    });

    return (
        <div>
            <Button buttonType={ButtonType.Action}
                    style={{padding: '0', width: '40px', height: '30px'}}
                    onClick={async (ev) => {
                        ev.stopPropagation();

                        await download();
                    }}>
                <div className={cl.iconButtonContent}>
                    <span><FontAwesomeIcon icon={faFilePdf}/></span>
                </div>
            </Button>
        </div>
    );
};

export default OrderDownloadTemplate;