import React from 'react';

type LogoProps = {
    animated?: boolean;
    style?:React.CSSProperties;
}

const Logo: React.FC<LogoProps> = ({animated, style}) => {
    return (
        <div className='loader' style={style}>
            <div className={`top-polygon ${animated !== undefined && animated ? 'top-polygon-animation' : ''}`}></div>
            <div className={`side-polygon left-polygon ${animated !== undefined && animated ? 'left-polygon-animation' : ''}`}></div>
            <div className={`side-polygon right-polygon ${animated !== undefined && animated ? 'right-polygon-animation' : ''}`}></div>
        </div>
    );
};

export default Logo;