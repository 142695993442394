import React, {ComponentPropsWithoutRef, useState} from 'react';
import cl from './Checkbox.module.css';

type CheckboxProps = {} & ComponentPropsWithoutRef<'input'> & { label: string};

const Checkbox: React.FC<CheckboxProps> = React.memo(({
                                                             label,
                                               value,
                                               onChange,
                                               ...rest
                                           }) => {
    const [state, setState] = useState(value);

    const onInputChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
        setState(ev.target.value);

        if (onChange) {
            onChange(ev);
        }
    };

    return (
        <div >
            <input type={'checkbox'}
                   value={state} style={{    verticalAlign: 'middle'}}
                   onChange={onInputChange}
                   className={cl.input}
                   {...rest}
            />
            <label style={{verticalAlign: 'middle', marginLeft: '10px'}}>{label}</label>


        </div>
    );
});

export default Checkbox;
