import React, {useEffect, useState} from 'react';
import {IRascoApplicationWizardState} from "../../../../../app/types/redux/IRascoApplicationWizardState";
import {useAppDispatch, useAppSelector} from "../../../../../app/store";
import {
    setRascoApplicationWizardState
} from "../../../../../features/rascoApplicationWizard/rascoApplicationWizardSlice";
import {Col, Row} from "react-bootstrap";
import cl from './ConfirmationWizard.module.css';
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {useTranslation} from "react-i18next";
import useWizard from "../../../../../components/ui/Wizard/utils/useWizard";
import {
    AjaxRequestInitiateEventArgs,
    Magnification,
    Navigation,
    PdfViewerComponent
} from "@syncfusion/ej2-react-pdfviewer";
import {Toolbar} from "@syncfusion/ej2-react-grids";
import {Inject} from "@syncfusion/ej2-react-dropdowns";
import {selectRascoApplication} from "../../../../../features/rascoApplication/rascoApplicationSelectors";
import {getInstance} from "@syncfusion/ej2-base";
import instance from "../../../../../app/api/apiAxios";


const footerInitialState: IRascoApplicationWizardState = {
    isPreviousButtonDisabled: false,
    showPreviousButton: true,
    isNextButtonDisabled: true,
    showNextButton: false,
    isCancelButtonDisabled: false,
    showCancelButton: true,
    showProgressBar: true,
    showFooter: true,
    cancelButtonAlign: 'left',
    nextButtonAlign: 'right',
    previousButtonAlign: 'left'
}

const ConfirmationWizard = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {goToNextStep} = useWizard();
    const application = useAppSelector(selectRascoApplication);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [isRetried, setIsRetried] = useState(false);

    useEffect(() => {
        dispatch(setRascoApplicationWizardState(footerInitialState));
    }, [dispatch]);

    const ajaxRequestInitiate = (ev: AjaxRequestInitiateEventArgs) => {
        const viewer = document.getElementById('viewer');

        if (viewer) {
            const viewerInstance: any = getInstance(viewer, PdfViewerComponent);
            if (viewerInstance && viewerInstance.serverActionSettings.load === 'Load') {
                ev.JsonData['application'] = JSON.stringify(application);

                viewerInstance.setJsonData(ev.JsonData);
            }
        }
    }

    if (!application) {
        return null;
    }

    return (
        <Row className={cl.h100}>
            <Col sm={8}>
                <PdfViewerComponent id="viewer"
                                    documentPath="HTTP Succinctly.pdf"
                                    serviceUrl={`${process.env.REACT_APP_WEB_API}/api/rascoApplication`}
                                    documentLoad={() => setDisabled(false)}
                                    showNotificationDialog={true}
                                    ajaxRequestFailed={(ev: any) => {
                                        if (ev && ev.action === 'Load' && ev.errorStatusCode === 401 && !isRetried) {
                                            (async () => {
                                                try {
                                                    setIsRetried(true);

                                                    await instance.post('api/authentication/refreshToken');

                                                    const viewer = document.getElementById('viewer');
                                                    if (viewer) {
                                                        const viewerInstance: any = getInstance(viewer, PdfViewerComponent);

                                                        if (viewerInstance) {
                                                            viewerInstance.load('HTTP Succinctly.pdf');
                                                        }
                                                    }
                                                } catch (error) {
                                                    if (error instanceof Error) {
                                                        console.log(error.message);
                                                    }
                                                }
                                            })();
                                        }
                                    }}
                                    ajaxRequestSettings={{
                                        ajaxHeaders: [],
                                        withCredentials: true,
                                    }}
                                    ajaxRequestInitiate={ajaxRequestInitiate}
                                    style={{'height': '100%'}}>
                    <Inject services={[Toolbar, Magnification, Navigation]}/>
                </PdfViewerComponent>
            </Col>
            <Col sm={4}>
                <div className={cl.secondColumn}>
                    <div style={{fontWeight: 'bold'}}>
                        {t("application.confirmation-wizard.check-again-label")}
                    </div>
                    <div>
                        {t("application.confirmation-wizard.click-send-label-1")}
                        <br/>
                        {t("application.confirmation-wizard.click-send-label-2")}
                    </div>
                    <div>
                        {t("application.confirmation-wizard.send-label-1")}
                        <br/>
                        {t("application.confirmation-wizard.send-label-2")}
                        <br/>
                        {t("application.confirmation-wizard.send-label-3")}
                        <br/>
                        {t("application.confirmation-wizard.send-label-4")}
                    </div>
                    <Button buttonType={ButtonType.Primary}
                            id={'send-btn'}
                            onClick={goToNextStep}
                            disabled={disabled}
                            style={{fontSize: '20px', padding: '10px'}}>
                        {t("application.confirmation-wizard.send-btn")}
                    </Button>
                    <div className={cl.bottomConfirmationLabelContainer}>
                        <span style={{color: 'red', fontSize: '18px', marginRight: '4px'}}>*</span>
                        {t("application.truck-wizard.confirmation-label-2")}
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default ConfirmationWizard;