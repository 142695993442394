import {configureStore} from "@reduxjs/toolkit";
import authenticationReducer from '../../features/authentication/authenticationSlice';
import applicationReducer from '../../features/application/applicationSlice';
import rascoApplicationWizardReducer from '../../features/rascoApplicationWizard/rascoApplicationWizardSlice';
import rascoApplicationReducer from '../../features/rascoApplication/rascoApplicationSlice';

const store = configureStore({
    reducer: {
        authentication: authenticationReducer,
        application: applicationReducer,
        rascoApplicationWizard: rascoApplicationWizardReducer,
        rascoApplication: rascoApplicationReducer
    }
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;