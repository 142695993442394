import {useCallback, useEffect, useState} from "react";

type ServerError = string | null | undefined;

const useServerError = (error: ServerError): [ServerError, (error: string) => void] => {
    const [value, setValue] = useState<string | undefined | null>(error);

    const setError = useCallback((err: ServerError) => {
        setValue(err);
    }, []);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (value) {
            timeoutId = setTimeout(() => {
                setValue(null);
            }, 10000);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        }
    }, [value]);

    return [value, setError];
};

export default useServerError;