import cl from './Label.module.css';
import React from "react";

export enum LabelType {
    Warning = 0,
    Information
}

type LabelProps = {
    text: string;
    center?: boolean;
    animated?: boolean;
    labelType: LabelType;
}

const Label: React.FC<LabelProps> = ({
                                         text,
                                         center,
                                         labelType,
                                         animated
                                     }) => {
    return (
        <div data-type={LabelType[labelType].toString().toLowerCase()}
             className={`${cl.label} ${center !== undefined && center ? cl.center : ''} ${animated !== undefined && animated ? cl.animation : ''}`}>
            {text}
        </div>
    );
};

export default Label;