import {IRascoApplicationOrderItem, IRascoApplicationState} from "../../app/types/redux/IRascoApplicationState";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICompanyBranchItem} from "../../app/types/models/ICompanyBranchItem";
import moment from "moment";
import uuid from "react-uuid";
import {TransportType} from "../../app/types/enums/TransportType";

export type IRascoApplicationOrderItemPayloadType = IRascoApplicationOrderItem & {
    updateField: keyof IRascoApplicationOrderItem
};

const initialState: IRascoApplicationState = {
    companyBranchItemId: null,
    companyBranchItem: null,
    confirmation: undefined,
    email: '',
    employeeName: '',
    orderDate: undefined,
    trailerLicencePlate: '',
    truckLicencePlate: '',
    doubleTrailerLicencePlate: '',
    city: '',
    sealNumber: '',
    arrivalDate: undefined,
    arrivalTime: undefined,
    loadingTime: undefined,
    loadingDate: undefined,
    orderItems: [],
    utcOffset: undefined
};

const rascoApplicationSlice = createSlice({
    name: 'rascoApplication',
    initialState,
    reducers: {
        setCompanyBranchItem: (state, action: PayloadAction<ICompanyBranchItem>) => {
            state.companyBranchItemId = action.payload.id;
            state.companyBranchItem = action.payload;
        },
        setConfirmation: (state, action: PayloadAction<string | undefined>) => {
            state.confirmation = action.payload;
        },
        setEmail: (state, action: PayloadAction<string | undefined>) => {
            state.email = action.payload;
        },
        setOrderDate: (state, action: PayloadAction<string>) => {
            state.orderDate = action.payload;
        },
        setEmployeeName: (state, action: PayloadAction<string>) => {
            state.employeeName = action.payload;
        },
        setTruckLicencePlate: (state, action: PayloadAction<string>) => {
            state.truckLicencePlate = action.payload;
        },
        setTrailerLicencePlate: (state, action: PayloadAction<string>) => {
            state.trailerLicencePlate = action.payload;
        },
        setDoubleTrailerLicencePlate: (state, action: PayloadAction<string>) => {
            state.doubleTrailerLicencePlate = action.payload;
        },
        setSealNumber: (state, action: PayloadAction<string | undefined>) => {
            state.sealNumber = action.payload;
        },
        setCity: (state, action: PayloadAction<string | undefined>) => {
            state.city = action.payload;
        },
        setArrivalDate: (state, action: PayloadAction<string | undefined>) => {
            state.arrivalDate = action.payload;
        },
        setArrivalTime: (state, action: PayloadAction<string | undefined>) => {
            state.arrivalTime = action.payload;
        },
        setLoadingDate: (state, action: PayloadAction<string | undefined>) => {
            state.loadingDate = action.payload;
        },
        setLoadingTime: (state, action: PayloadAction<string | undefined>) => {
            state.loadingTime = action.payload;
        },
        setOrderItems: (state, action: PayloadAction<Array<IRascoApplicationOrderItem>>) => {
            state.orderItems = action.payload
        },
        setTransportType: (state, action: PayloadAction<TransportType | undefined>) => {
            state.transportType = action.payload
        },
        addOrderItem: (state, action: PayloadAction<IRascoApplicationOrderItem>) => {
            if (!state.orderItems) {
                state.orderItems = [];
            }

            state.orderItems.push(action.payload);
        },
        setUtcOffset: (state, action: PayloadAction<number>) => {
            state.utcOffset = action.payload;
        },
        updateOrderItem: (state, action: PayloadAction<IRascoApplicationOrderItemPayloadType>) => {
            if (!state.orderItems) {
                state.orderItems = [];
            }

            const item = state.orderItems.find(item => item.position === action.payload.position);

            if (item !== undefined && item !== null) {
                if (action.payload.updateField === 'shipmentIdentificationNumber') {
                    item.shipmentIdentificationNumber = action.payload.shipmentIdentificationNumber;
                }

                if (action.payload.updateField === 'amount') {
                    item.amount = action.payload.amount;
                }

                if (action.payload.updateField === 'weight') {
                    item.weight = action.payload.weight;
                }

                if (action.payload.updateField === 'content') {
                    item.content = action.payload.content;
                    item.orderItemContentTypeId = action.payload.orderItemContentTypeId;
                }

                if (action.payload.updateField === 'cost') {
                    item.cost = action.payload.cost;
                }

                if (action.payload.updateField === 'client') {
                    item.client = action.payload.client;
                    item.clientId = action.payload.clientId;
                    item.costNotes = action.payload.costNotes;
                }
            }
        },
        deleteOrderItem: (state, action: PayloadAction<IRascoApplicationOrderItem>) => {
            if (!state.orderItems) {
                return;
            }

            if (!action.payload) {
                return;
            }

            state.orderItems = state.orderItems.filter(item => {
                return item.position !== action.payload.position;
            });

            let index = 1;
            for (const elem of state.orderItems) {
                elem.position = index;

                index += 1;
            }
        },
        clear: (state) => {
            state.companyBranchItemId = initialState.companyBranchItemId;
            state.companyBranchItem = initialState.companyBranchItem;

            state.employeeName = initialState.employeeName;
            state.orderDate = initialState.orderDate;
            state.email = initialState.email;
            state.confirmation = initialState.confirmation

            state.truckLicencePlate = initialState.truckLicencePlate;
            state.trailerLicencePlate = initialState.trailerLicencePlate;
            state.doubleTrailerLicencePlate = initialState.doubleTrailerLicencePlate;

            state.sealNumber = initialState.sealNumber;
            state.city = initialState.city;

            state.loadingDate = initialState.loadingDate;
            state.loadingTime = initialState.loadingTime;
            state.arrivalDate = initialState.arrivalDate;
            state.arrivalTime = initialState.arrivalTime;
            state.orderItems = initialState.orderItems;
            state.id = initialState.id;
            state.transportType = initialState.transportType;
        },
        setRascoApplication: (state, action: PayloadAction<IRascoApplicationState>) => {
            state.id = action.payload.id;
            state.email = action.payload.email;
            state.employeeName = action.payload.employeeName;
            //state.orderDate = action.payload.orderDate;

            state.truckLicencePlate = action.payload.truckLicencePlate;
            state.trailerLicencePlate = action.payload.trailerLicencePlate;
            state.doubleTrailerLicencePlate = action.payload.doubleTrailerLicencePlate;

            state.city = action.payload.city;
            state.sealNumber = action.payload.sealNumber;
            state.transportType = action.payload.transportType;

            if (action.payload.orderDt) {
                const orderDtLocal = moment.utc(action.payload.orderDt).local();

                state.orderDate = orderDtLocal.format('DD.MM.YYYY');
            }

            if (action.payload.arrivalDt) {
                const arrivalDtLocal = moment.utc(action.payload.arrivalDt).local();

                state.arrivalDate = arrivalDtLocal.format('DD.MM.YYYY');
                state.arrivalTime = arrivalDtLocal.format("DD.MM.YYYY HH:mm");
            }

            if (action.payload.loadingDt) {
                const loadingDtLocal = moment.utc(action.payload.loadingDt).local();

                state.loadingTime = loadingDtLocal.format('DD.MM.YYYY HH:mm');
                state.loadingDate = loadingDtLocal.format("DD.MM.YYYY");
            }

            state.orderItems = action.payload.orderItems;

            if (state.orderItems) {
                for (const elem of state.orderItems) {
                    elem.uuid = uuid();
                }
            }
        }
    }
});

export const {
    setCompanyBranchItem,
    setEmail,
    setConfirmation,
    setOrderDate,
    clear,
    setEmployeeName,
    setTruckLicencePlate,
    setTrailerLicencePlate,
    setDoubleTrailerLicencePlate,
    setSealNumber,
    setCity,
    setArrivalDate,
    setArrivalTime,
    setLoadingDate,
    setLoadingTime,
    addOrderItem,
    deleteOrderItem,
    updateOrderItem,
    setUtcOffset,
    setRascoApplication,
    setTransportType
} = rascoApplicationSlice.actions;

export default rascoApplicationSlice.reducer;
