import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../app/store";
import {selectAuthenticationLoading} from "../../../features/authentication/authenticationSelectors";
import LoadingScreen from "../../ui/LoadingScreen/LoadingScreen";
import {Outlet} from "react-router-dom";
import {validateIsUserAuth} from "../../../features/authentication/authenticationSlice";

const BaseLayout: React.FC = () => {
    const isLoading = useAppSelector(selectAuthenticationLoading);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(validateIsUserAuth());
    }, [dispatch]);

    if (isLoading) {
        return <LoadingScreen/>;
    }
    return <Outlet/>
};

export default BaseLayout;