import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../app/store";
import {
    setRascoApplicationWizardState
} from "../../../../../features/rascoApplicationWizard/rascoApplicationWizardSlice";
import {IRascoApplicationWizardState} from "../../../../../app/types/redux/IRascoApplicationWizardState";
import cl from './TruckWizard.module.css';
import {useTranslation} from "react-i18next";
import WizardInput from "../../components/WizardInput/WizardInput";
import {selectRascoApplication} from "../../../../../features/rascoApplication/rascoApplicationSelectors";
import {
    setArrivalDate, setArrivalTime,
    setCity,
    setLoadingDate, setLoadingTime, setSealNumber
} from "../../../../../features/rascoApplication/rascoApplicationSlice";
import {ChangedEventArgs, DatePickerComponent, TimePickerComponent} from "@syncfusion/ej2-react-calendars";
import moment from "moment/moment";
import {dateToPreviousNearest30Minutes} from "../../../../../utils/date";

const footerInitialState: IRascoApplicationWizardState = {
    isPreviousButtonDisabled: false,
    showPreviousButton: true,
    isNextButtonDisabled: true,
    showNextButton: true,
    isCancelButtonDisabled: false,
    showCancelButton: true,
    showProgressBar: true,
    showFooter: true,
    cancelButtonAlign: 'left',
    nextButtonAlign: 'right',
    previousButtonAlign: 'right'
}

function isValid(city?: string,
                 sealNumber?: string,
                 arrivalTime?: string,
                 arrivalDate?: string,
                 loadingDate?: string,
                 loadingTime?: string): boolean {
    return city !== undefined && city !== null && city !== ''
        && sealNumber !== undefined && sealNumber !== null && sealNumber !== ''
        && loadingDate !== undefined && loadingDate !== null && loadingDate !== ''
        && loadingTime !== undefined && loadingTime !== null && loadingTime !== ''
        && arrivalDate !== undefined && arrivalDate !== null && arrivalDate !== ''
        && arrivalTime !== undefined && arrivalTime !== null && arrivalTime !== ''
}

const TruckWizard = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {
        sealNumber,
        city,
        arrivalDate,
        arrivalTime,
        loadingDate,
        loadingTime
    } = useAppSelector(selectRascoApplication);

    useEffect(() => {
        dispatch(setRascoApplicationWizardState(footerInitialState))
    }, [dispatch]);

    useEffect(() => {
        const currentDate = dateToPreviousNearest30Minutes(moment());
        if (!loadingDate) {
            dispatch(setLoadingDate(currentDate.format('DD.MM.YYYY')));
        }

        if (!loadingTime) {
            dispatch(setLoadingTime(currentDate.format('DD.MM.YYYY HH:mm')));
        }

        if (!arrivalDate) {
            dispatch(setArrivalDate(currentDate.format('DD.MM.YYYY')));
        }

        if (!arrivalTime) {
            dispatch(setArrivalTime(currentDate.add(2, 'hours').format('DD.MM.YYYY HH:mm')));
        }
    }, [dispatch])

    useEffect(() => {
        dispatch(setRascoApplicationWizardState(
            isValid(city, sealNumber, arrivalTime, arrivalDate, loadingDate, loadingTime)
                ? {...footerInitialState, isNextButtonDisabled: false}
                : footerInitialState
        ));
    }, [dispatch, sealNumber, city, arrivalDate, arrivalTime, loadingDate, loadingTime]);


    return (
        <div className={cl.container}>
            <div>
                {t("application.truck-wizard.header-title")}
            </div>
            <div>
                <div className={cl.topConfirmationLabelContainer}>
                    <span style={{color: 'red', fontSize: '18px', marginRight: '4px'}}>*</span>
                    {t("application.truck-wizard.confirmation-label-1")}
                </div>
                <div className={cl.tableContainer}>
                    <table className={cl.responsiveTable}>
                        <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">{t("application.truck-wizard.date-column-label")}</th>
                            <th scope="col">{t("application.truck-wizard.time-column-label")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">{t("application.truck-wizard.loading-date-time-label")}</th>
                            <td className='syncfusion-control-input-container'
                                data-title={t("application.truck-wizard.loading-date-label")}>
                                <DatePickerComponent placeholder={t("application.truck-wizard.loading-date-label")}
                                                     id={'loading-date'}
                                                     value={moment(loadingDate, 'DD.MM.YYYY').toDate()}
                                                     change={(ev: ChangedEventArgs) => {
                                                         dispatch(setLoadingDate(ev.value !== undefined && ev.value !== null
                                                             ? moment(ev.value).format("DD.MM.YYYY")
                                                             : undefined));
                                                     }}
                                                     showClearButton={false}
                                                     format='dd.MM.yyyy'/>

                                {!loadingDate
                                    ? <div className='server-error-label'
                                           style={{textAlign: 'left'}}>
                                        {t("shared.errors.required-error").replace('{0}', t("application.truck-wizard.loading-date-label"))}
                                    </div>
                                    : null
                                }
                            </td>
                            <td data-title={t("application.truck-wizard.loading-time-label")}
                                className='syncfusion-control-input-container'>
                                <TimePickerComponent placeholder={t("application.truck-wizard.loading-time-label")}
                                                     id={'loading-time'}
                                                     value={moment(loadingTime, 'DD.MM.YYYY HH:mm').toDate()}
                                                     change={(ev: ChangedEventArgs) => {
                                                         dispatch(setLoadingTime(ev.value !== undefined && ev.value !== null
                                                             ? moment(ev.value).format("DD.MM.YYYY HH:mm")
                                                             : undefined));
                                                     }}
                                                     step={30}
                                                     showClearButton={false}
                                                     format='HH:mm'/>
                                {!loadingTime
                                    ? <div className='server-error-label'
                                           style={{textAlign: 'left'}}>
                                        {t("shared.errors.required-error").replace('{0}', t("application.truck-wizard.loading-time-label"))}
                                    </div>
                                    : null
                                }
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">{t("application.truck-wizard.arrival-date-time-label")}</th>
                            <td className='syncfusion-control-input-container'
                                data-title={t("application.truck-wizard.arrival-date-label")}>
                                <DatePickerComponent placeholder={t("application.truck-wizard.arrival-date-label")}
                                                     id={'arrival-date'}
                                                     value={moment(arrivalDate, 'DD.MM.YYYY').toDate()}
                                                     change={(ev: ChangedEventArgs) => {
                                                         dispatch(setArrivalDate(ev.value !== undefined && ev.value !== null
                                                             ? moment(ev.value).format("DD.MM.YYYY")
                                                             : undefined));
                                                     }}
                                                     showClearButton={false}
                                                     format='dd.MM.yyyy'/>

                                {!arrivalDate
                                    ? <div className='server-error-label'
                                           style={{textAlign: 'left'}}>
                                        {t("shared.errors.required-error").replace('{0}', t("application.truck-wizard.arrival-date-label"))}
                                    </div>
                                    : null
                                }
                            </td>
                            <td data-title={t("application.truck-wizard.arrival-time-label")}
                                className='syncfusion-control-input-container'>
                                <TimePickerComponent placeholder={t("application.truck-wizard.arrival-time-label")}
                                                     id={'arrival-time'}
                                                     value={moment(arrivalTime, 'DD.MM.YYYY HH:mm').toDate()}
                                                     change={(ev: ChangedEventArgs) => {
                                                         dispatch(setArrivalTime(ev.value !== undefined && ev.value !== null
                                                             ? moment(ev.value).format("DD.MM.YYYY HH:mm")
                                                             : undefined));
                                                     }}
                                                     step={30}
                                                     showClearButton={false}
                                                     format='HH:mm'/>
                                {!arrivalTime
                                    ? <div className='server-error-label'
                                           style={{textAlign: 'left'}}>
                                        {t("shared.errors.required-error").replace('{0}', t("application.truck-wizard.arrival-time-label"))}
                                    </div>
                                    : null
                                }
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                {t("application.truck-wizard.cap-seal-number")}
                            </th>
                            <td colSpan={2} data-title={t("application.truck-wizard.cap-seal-number")}>
                                <WizardInput showClearButton={false}
                                             id={'seal-number'}
                                             value={sealNumber}
                                             onChange={(ev) => {
                                                 dispatch(setSealNumber(ev.target.value));
                                             }}
                                             placeholder={t("application.truck-wizard.cap-seal-number")}/>
                                {!sealNumber
                                    ? <div className='server-error-label'
                                           style={{textAlign: 'left'}}>
                                        {t("shared.errors.required-error").replace('{0}', t("application.truck-wizard.cap-seal-number"))}
                                    </div>
                                    : null
                                }
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                {t("application.truck-wizard.city")}
                            </th>
                            <td colSpan={2} data-title={t("application.truck-wizard.city")}>
                                <WizardInput showClearButton={false}
                                             id={'city'}
                                             value={city}
                                             onChange={(ev) => {
                                                 dispatch(setCity(ev.target.value));
                                             }}
                                             placeholder={t("application.truck-wizard.city")}/>
                                {!city
                                    ? <div className='server-error-label'
                                           style={{textAlign: 'left'}}>
                                        {t("shared.errors.required-error").replace('{0}', t("application.truck-wizard.city"))}
                                    </div>
                                    : null
                                }
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TruckWizard;