import React, {useEffect, useState} from 'react';
import {
    ChangeEventArgs,
    ComboBoxComponent,
    CustomValueSpecifierEventArgs,
    FilteringEventArgs, SelectEventArgs
} from "@syncfusion/ej2-react-dropdowns";
import {DataManager, Query, WebApiAdaptor} from "@syncfusion/ej2-data";
import instance from "../../../app/api/apiAxios";
import {Ajax} from "@syncfusion/ej2-base";
import useIsFirstRender from "../../../hooks/useIsFirstRender";

type SyncfusionComboBoxProps = {
    query?: Query;
    url: string;
    showClearButton?: boolean;
    enabled: boolean;
    placeholder?: string;
    value: string | number | boolean | undefined;
    change: (ev: ChangeEventArgs) => void;
    filtering: (ev: FilteringEventArgs) => void;
    fields: FieldProps;
    allowFiltering?: boolean;
    allowCustom?: boolean;
    custom?: (ev: CustomValueSpecifierEventArgs) => void;
    select?: (ev: SelectEventArgs) => void;
    id?: string;
}

type FieldProps = {
    text: string;
    value: string;
}

export class WithCredentialsWebApiAdaptor extends WebApiAdaptor {
    public beforeSend(args: DataManager, xhr: XMLHttpRequest, settings: Ajax) {
        xhr.withCredentials = true;

        super.beforeSend(args, xhr, settings);
    }
}

const SyncfusionComboBox: React.FC<SyncfusionComboBoxProps> = ({
                                                                   query,
                                                                   url,
                                                                   showClearButton,
                                                                   enabled,
                                                                   placeholder,
                                                                   change,
                                                                   filtering,
                                                                   fields,
                                                                   allowFiltering,
                                                                   custom,
                                                                   value,
                                                                   allowCustom,
                                                                   select,
                                                                   id
                                                               }) => {
    const [q, setQ] = useState(query);
    const isFirstRender = useIsFirstRender();
    const [enabledLocal, setEnabledLocal] = useState<boolean>(true);

    const [dataSource, setDataSource] = useState(new DataManager({
        adaptor: new WithCredentialsWebApiAdaptor(),
        url: `${process.env.REACT_APP_WEB_API}${url}`,
    }));

    useEffect(() => {
        if (!isFirstRender) {
            setQ(query);
        }
    }, [query, isFirstRender]);

    const onActionFailure = (ev: { name: string, error: XMLHttpRequest }) => {
        if (ev.name && ev.error && ev.name === 'actionFailure' && ev.error.status === 401) {
            (async () => {
                setEnabledLocal(prev => !prev);

                try {
                    await instance.post('api/authentication/refreshToken');

                    setDataSource(new DataManager({
                        adaptor: new WithCredentialsWebApiAdaptor(),
                        url: `${process.env.REACT_APP_WEB_API}${url}`,
                    }));
                } catch (error) {
                    if (error instanceof Error) {
                        console.log(error.message);
                    }
                }

                setEnabledLocal(prev => !prev);
            })();
        }
    }

    return (
        <ComboBoxComponent query={q}
                           id={id}
                           dataSource={dataSource}
                           actionFailure={onActionFailure}
                           showClearButton={showClearButton}
                           placeholder={placeholder}
                           change={change}
                           value={value}
                           fields={fields}
                           allowCustom={allowCustom}
                           customValueSpecifier={custom}
                           allowFiltering={allowFiltering}
                           filtering={filtering}
                           select={select}
                           enabled={!enabledLocal ? enabledLocal : enabled}>

        </ComboBoxComponent>
    );
};

export default SyncfusionComboBox;