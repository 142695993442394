import apiAxios from "../app/api/apiAxios";

interface IUseDownloadFileProps {
    apiUrl: string;
    beforeDownload?: () => void;
    afterDownload?: () => void;
    actionFailure?: (err: Error) => void;
    getFileName: () => string;
}

interface IUseDownloadFileReturn {
    download: () => Promise<void>;
}

type UseDownloadFileSignature = (props: IUseDownloadFileProps) => IUseDownloadFileReturn;

const useDownloadFile: UseDownloadFileSignature = ({
                                                       apiUrl,
                                                       actionFailure,
                                                       beforeDownload,
                                                       afterDownload,
                                                       getFileName
                                                   }) => {
    const download = async () => {
        try {
            if (beforeDownload) {
                beforeDownload();
            }

            const {data} = await apiAxios.get(apiUrl, {
                responseType: 'blob'
            });

            const url = URL.createObjectURL(new Blob([data]));
            const elem = document.createElement('a');

            elem.href = url;

            elem.setAttribute('download', getFileName());

            document.body.appendChild(elem);

            elem.click();

            if (afterDownload) {
                afterDownload();
            }

            URL.revokeObjectURL(url);
        } catch (error) {
            if (error instanceof Error && actionFailure) {
                actionFailure(error);
            }
        }
    }

    return {download};
}

export default useDownloadFile;