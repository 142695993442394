import {IApiErrorResourceModelItem} from "../../apiError/IApiErrorResourceModelItem";
import {ResponseResultCode} from "../enums/ResponseResultCode";
import {IOrderItem} from "./IOrderItem";

export interface IOrder {
    id: number;
    utcOffset?: number;
    orderItems: Array<IOrderItem>;
}

export const orderApiErrors: Array<IApiErrorResourceModelItem> = [
    {
        resultCode: ResponseResultCode.Unauthorized,
        resourceName: 'shared.errors.unauthorized'
    },
    {
        resultCode: ResponseResultCode.ServerError,
        resourceName: 'shared.errors.server-error'
    },
    {
        resultCode: ResponseResultCode.NotValidData,
        field: 'id',
        resourceName: 'order.errors.not-valid-data-id-order'
    },
    {
        resultCode: ResponseResultCode.NotFound,
        field: 'order',
        resourceName: 'order.errors.not-valid-data-id-order'
    },
    {
        resultCode: ResponseResultCode.Forbidden,
        resourceName: 'order.errors.not-valid-data-id-order'
    },
    {
        resultCode: ResponseResultCode.Forbidden,
        field: 'type',
        resourceName: 'order.errors.forbidden-type'
    },
    {
        resultCode: ResponseResultCode.Forbidden,
        field: 'status',
        resourceName: 'order.errors.forbidden-status'
    },
];