import React, {ComponentPropsWithoutRef, useState} from 'react';
import cl from './Input.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";

type WizardInputProps = {} & ComponentPropsWithoutRef<'input'>;

const Input: React.FC<WizardInputProps> = React.memo(({
                                               type,
                                               value,
                                               onChange,
                                               ...rest
                                           }) => {
    const [state, setState] = useState(value);

    const onInputChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
        setState(ev.target.value);

        if (onChange) {
            onChange(ev);
        }
    };

    return (
        <div style={{position: 'relative'}}>
            <input type={type === 'search' ? 'text' : type}
                   value={state}
                   onChange={onInputChange}
                   className={cl.input}
                   data-type={type}
                   {...rest}
            />
            {type === "search"
                ? <span className={cl.searchBtn}><FontAwesomeIcon icon={faMagnifyingGlass}/></span>
                : null
            }
        </div>
    );
});

export default Input;
