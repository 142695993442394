import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './app/i18n/i18n';
import LoadingScreen from "./components/ui/LoadingScreen/LoadingScreen";
import {Provider} from "react-redux";
import store from "./app/store/store";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import routes from "./app/router/routes";
import setupInterceptors from "./app/api/services/axiosService";
import 'bootstrap/dist/css/bootstrap.min.css';

import "../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";
import "../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css";
import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';

import {registerLicense} from "@syncfusion/ej2-base";

setupInterceptors(store);
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENCE_KEY || '');

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Suspense fallback={<LoadingScreen/>}>
        <Provider store={store}>
            <RouterProvider router={createBrowserRouter(routes)}/>
        </Provider>
    </Suspense>
);

