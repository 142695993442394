import {useAppDispatch, useAppSelector} from "../../../../../app/store";
import {
    selectRascoApplicationWizardState
} from "../../../../../features/rascoApplicationWizard/rascoApplicationWizardSelectors";
import useWizard from "../../../../../components/ui/Wizard/utils/useWizard";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {
    setHeaderMenuDisabledState,
    setHeaderMenuShownState
} from "../../../../../features/application/applicationSlice";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import cl from './WizardFooter.module.css';
import React, {useEffect} from "react";
import WizardProgressBar from "../WizardProgressBar/WizardProgressBar";
import useMediaQuery from "../../../../../hooks/useMediaQuery";
import {
    setRascoApplicationWizardProgressBarState
} from "../../../../../features/rascoApplicationWizard/rascoApplicationWizardSlice";
import {clear} from "../../../../../features/rascoApplication/rascoApplicationSlice";

const style = {
    width: '20%',
    fontSize: '19px',
    margin: '0 5px',
    top: '50%',
    transform: 'translateY(-50%)'
};

const buttonMobileViewStyle = {
    fontSize: '17px',
    margin: '0 5px',
    top: '50%',
    padding: '7px 20px',
    transform: 'translateY(-50%)'
}

const WizardFooter = () => {
    const {
        showPreviousButton,
        isPreviousButtonDisabled,
        isNextButtonDisabled,
        showNextButton,
        isCancelButtonDisabled,
        showCancelButton,
        showProgressBar,
        showFooter,
        nextButtonAlign,
        previousButtonAlign,
        cancelButtonAlign
    } = useAppSelector(selectRascoApplicationWizardState);
    const {goToPreviousStep, goToNextStep} = useWizard();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isMaxWidth600Match = useMediaQuery('(max-width: 600px)');
    const isMaxWidth680Match = useMediaQuery('(max-width: 680px)');

    useEffect(() => {
        dispatch(setRascoApplicationWizardProgressBarState(!isMaxWidth600Match));
    }, [isMaxWidth600Match, dispatch]);

    const onCancelButtonClick = (): void => {
        dispatch(setHeaderMenuDisabledState(false));
        dispatch(setHeaderMenuShownState(true));

        navigate('/order/application');

        dispatch(clear());
    }

    if (!showFooter) {
        return null;
    }

    return (
        <div className={(showProgressBar && !isMaxWidth600Match) ? cl.withProgressBarContainer : cl.container}>
            <div className={cl.btnRow}>
                {showCancelButton &&
                    <Button buttonType={ButtonType.Warning}
                            id={'cancel-btn'}
                            style={isMaxWidth680Match ? {...buttonMobileViewStyle, float: cancelButtonAlign === 'right' ? 'right' : 'left'} : {
                                ...style,
                                float: cancelButtonAlign === 'right' ? 'right' : 'left'
                            }}
                            disabled={isCancelButtonDisabled}
                            onClick={onCancelButtonClick}>
                        {t("btn.cancel")}
                    </Button>
                }
                {showNextButton &&
                    <Button buttonType={ButtonType.Primary}
                            id={'continue-btn'}
                            style={isMaxWidth680Match ? {...buttonMobileViewStyle, float: nextButtonAlign === 'right' ? 'right' : 'left'} : {
                                ...style,
                                float: nextButtonAlign === 'right' ? 'right' : 'left'
                            }}
                            disabled={isNextButtonDisabled}
                            onClick={goToNextStep}>
                        {t("btn.continue")}
                    </Button>
                }
                {showPreviousButton &&
                    <Button buttonType={ButtonType.Secondary}
                            id={'back-btn'}
                            style={isMaxWidth680Match ? {...buttonMobileViewStyle, float: previousButtonAlign === 'right' ? 'right' : 'left'} : {
                                ...style,
                                float: previousButtonAlign === 'right' ? 'right' : 'left'
                            }}
                            disabled={isPreviousButtonDisabled}
                            onClick={goToPreviousStep}>
                        {t("btn.back")}
                    </Button>
                }
            </div>
            {showProgressBar && !isMaxWidth600Match
                ? <WizardProgressBar/>
                : null
            }
        </div>
    );
};

export default WizardFooter;