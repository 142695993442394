import React, {useEffect, useState} from "react";
import cl from './OrderItemChooseItem.module.css';
import {useTranslation} from "react-i18next";

import {IOrderItemForAvis} from "../../../../../../../../app/types/models/IOrderItemForAvis";
import moment from "moment";
import {ChangeEventArgs} from "@syncfusion/ej2-react-dropdowns";
import Button, {ButtonType} from "../../../../../../../../components/ui/Button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSearchPlus} from "@fortawesome/free-solid-svg-icons";


type OrderItemChooserItemProps = {
    data: IOrderItemForAvis
    onSelected: (ev: IOrderItemForAvis) => void;
};


const OrderItemChooserItem: React.FC<OrderItemChooserItemProps> = ({
data,
                                                                       onSelected
                                                                         }) => {


    const {t} = useTranslation();

    function onItemSelected(e: IOrderItemForAvis) {
        onSelected(e)
    }


    return(
    <div className={cl.container}>
        <div>
            {moment(data.arrivedOn).format("YYYY-MM-DD HH:mm")}
        </div>
        <div>
            {data.lagerNum}
        </div>
        <div>
            {data.identificationNumber}
        </div>
        <div>
            <Button buttonType={ButtonType.Primary}
                    id={'select-cargo-btn'}
                    onClick={e => {onItemSelected(data)}}
                    style={{ padding: '10px'}}>
                <div className={cl.btn}>
                                    <span>
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </span>
                    <span>
                                        {t("btn.select")}
                                    </span>
                </div>
            </Button>
        </div>
        <div>
            {data.customer}
        </div>
        <div>
            {data.content}
        </div>
        <div>
            {data.weight}
        </div>
        <div>
            {data.amount}
        </div>



    </div>);
}

export default OrderItemChooserItem;
