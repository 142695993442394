import React, {useEffect} from 'react';
import {useAppSelector} from "../../../../../app/store";
import {
    selectRascoApplicationWizardState
} from "../../../../../features/rascoApplicationWizard/rascoApplicationWizardSelectors";
import cl from './WizardProgressBar.module.css';
import {
    IWizardProgressBarStep,
    WizardProgressBarStatus
} from "../../../../../app/types/redux/IRascoApplicationWizardState";
import useWizard from "../../../../../components/ui/Wizard/utils/useWizard";
import {useDispatch} from "react-redux";
import {updateProgressBarItem} from "../../../../../features/rascoApplicationWizard/rascoApplicationWizardSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBarsProgress, faCheck} from "@fortawesome/free-solid-svg-icons";
import useMediaQuery from "../../../../../hooks/useMediaQuery";

const WizardProgressBar: React.FC = () => {
        const {progressBarItems} = useAppSelector(selectRascoApplicationWizardState);
        const {activeStep} = useWizard();
        const dispatch = useDispatch();
        const isMaxWidth900 = useMediaQuery('(max-width: 900px)');

        useEffect(() => {
            if (progressBarItems) {
                dispatch(updateProgressBarItem({
                    orderStep: activeStep,
                    status: WizardProgressBarStatus.Progress
                }));

                for(const elem of progressBarItems.filter(item => item.status !== WizardProgressBarStatus.Complete && item.orderStep < activeStep))
                {
                    dispatch(updateProgressBarItem({
                        orderStep: elem.orderStep,
                        status: WizardProgressBarStatus.Complete
                    }));
                }

                for(const elem of progressBarItems.filter(item => item.status === WizardProgressBarStatus.Progress && item.orderStep > activeStep))
                {
                    dispatch(updateProgressBarItem({
                        orderStep: elem.orderStep,
                        status: WizardProgressBarStatus.UnComplete
                    }));
                }
            }
        }, [activeStep, dispatch]);

        if (!progressBarItems) {
            return null;
        }

        return (
            <div className={cl.container}>
                {progressBarItems.map((item: IWizardProgressBarStep) => {
                    let statusClass: string = '';
                    switch (item.status) {
                        case WizardProgressBarStatus.UnComplete:
                            statusClass = '';
                            break;
                        case WizardProgressBarStatus.Complete:
                            statusClass = cl.complete
                            break;
                        case WizardProgressBarStatus.Progress:
                            statusClass = cl.progress;
                            break;

                    }
                    return (
                        <div key={item.orderStep} className={`${cl.step} ${statusClass} `}>
                            {item.status === WizardProgressBarStatus.Complete
                                ? <FontAwesomeIcon className={cl.icon} icon={faCheck}/>
                                : null
                            }
                            {item.status === WizardProgressBarStatus.Progress
                                ? <FontAwesomeIcon className={cl.icon} icon={faBarsProgress}/>
                                : null
                            }
                            <div className={cl.node}></div>
                            <div style={{height: '30px'}}></div>
                            <div className={cl.textContainer}>
                                <div className={cl.overflowHidden}>{item.label}</div>
                                {item.description && !isMaxWidth900
                                    ? <div className={cl.description}>{item.description}</div>
                                    : null
                                }
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
;

export default WizardProgressBar;