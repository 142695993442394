import React from 'react';
import moment from "moment";

const OrderDateTemplate = (props: any) => {
    if (!props[props.column.field]) {
        return null;
    }

//    const date = moment(props[props.column.field] + '+00:00').local().format('DD.MM.YYYY');
    const date = moment(props["orderDateStr"]).local().format('DD.MM.YYYY');

    return (
        <div>
            {date}
        </div>
    );
};

export default OrderDateTemplate;