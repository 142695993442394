import React from 'react';
import cl from './Login.module.css';
import bgImage from '../../../assets/images/login_bg3.jpg';
import {useTranslation} from "react-i18next";
import Logo from "../../../components/ui/Logo/Logo";
import {FormikHelpers, useFormik} from "formik";
import {ILogin} from "../../../app/types/models/ILogin";
import * as Yup from 'yup';
import Button, {ButtonType} from "../../../components/ui/Button/Button";
import LoginInput from "../../../components/ui/LoginInput/LoginInput";
import {faLock, faUser} from "@fortawesome/free-solid-svg-icons";
import useServerError from "../../../hooks/useServerError";
import Label, {LabelType} from "../../../components/ui/Label/Label";
import apiAxios from "../../../app/api/apiAxios";
import {IResponseModel} from "../../../app/types/models/IResponseModel";
import {AxiosError, AxiosResponse} from "axios";
import {ResponseResultCode} from "../../../app/types/enums/ResponseResultCode";
import {useAppDispatch} from "../../../app/store";
import {validateIsUserAuth} from "../../../features/authentication/authenticationSlice";
import getServerErrorResourceName from "../../../app/apiError";
import useGoogleReCaptchaV3 from "../../../hooks/useGoogleReCaptchaV3";
import LanguageSwitcher, {LanguageSwitcherTheme} from "../../../components/ui/LanguageSwitcher/LanguageSwitcher";

const initialValues: ILogin = {
    login: '',
    password: '',
    token: '',
}

const Login: React.FC = () => {
    const {t, i18n} = useTranslation();
    const [serverError, setServerError] = useServerError(null);
    const dispatch = useAppDispatch();
    const {
        executeReCaptcha,
        loaded
    } = useGoogleReCaptchaV3({siteKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '', language: i18n.language});

    const form = useFormik<ILogin>({
        initialValues,
        validationSchema: Yup.object({
            login: Yup.string()
                .required(t("shared.errors.required-error").replace('{0}', t("login.labels.login")))
                .max(100),
            password: Yup.string()
                .required(t("shared.errors.required-error").replace('{0}', t("login.labels.password")))
                .max(100),
        }),
        onSubmit: async (values: ILogin, actions: FormikHelpers<ILogin>) => {
            try {
                actions.setSubmitting(true);

                if (!loaded || !executeReCaptcha) {
                    setServerError(t("shared.errors.google-recaptcha-not-loaded-error"));

                    return;
                }

                const reCaptchaToken = await executeReCaptcha('login/submit');

                if (!reCaptchaToken) {
                    setServerError(t("shared.errors.google-recaptcha-not-loaded-error"));

                    return;
                }

                const response: AxiosResponse<IResponseModel, ILogin> = await apiAxios.post<IResponseModel>('api/authentication/login', {
                    login: values.login,
                    password: values.password,
                    token: reCaptchaToken,
                } as ILogin);

                if ((response.status === 200 || response.status === 204) &&
                    response.data && response.data.resultCode === ResponseResultCode.Ok) {
                    dispatch(validateIsUserAuth());
                }
            } catch (error) {
                if (error instanceof AxiosError && error.response !== undefined && error.response.data !== null) {
                    setServerError(t(getServerErrorResourceName('login', error.response.data.resultCode, error.response.data.field)));
                } else if (error instanceof Error) {
                    setServerError(`${t("shared.errors.server-error")} (${error.message})`);
                }
            }
            actions.setSubmitting(false);
        }
    });

    return (
        <div className={cl.container} style={{backgroundImage: `url(${bgImage})`}}>
            <div className={cl.logoContainer}>
                <Logo/>
            </div>
            <div className={cl.card}>
                <form className={cl.form} onSubmit={form.handleSubmit}>
                    <div className={cl.languageSwitcherContainer}>
                        <LanguageSwitcher theme={LanguageSwitcherTheme.Transparent} disabled={false}/>
                    </div>
                    <h4>
                        {t("login.labels.login-label")}
                        <b>{' ' + t("shared.rasco")}</b>
                    </h4>
                    {serverError
                        ?
                        <div className={cl.controlContainer}><Label labelType={LabelType.Warning}
                                                                    text={serverError}
                                                                    animated={true}
                                                                    center={true}/></div>
                        : null
                    }
                    <div className={cl.controlContainer}>
                        <LoginInput id="login"
                                    icon={faUser}
                                    name="login"
                                    autoComplete="login"
                                    type='text'
                                    disabled={form.isSubmitting}
                                    onChange={form.handleChange}
                                    value={form.values.login}
                                    error={form.touched.login && form.errors.login ? form.errors.login : undefined}
                                    placeholder={t("login.labels.login") ?? ''}/>
                    </div>
                    <div className={cl.controlContainer}>
                        <LoginInput icon={faLock}
                                    type='password'
                                    placeholder={t("login.labels.password") ?? ''}
                                    id="password"
                                    name="password"
                                    autoComplete="current-password"
                                    disabled={form.isSubmitting}
                                    onChange={form.handleChange}
                                    value={form.values.password}
                                    error={form.touched.password && form.errors.password ? form.errors.password : undefined}/>
                    </div>
                    <div className={`${cl.controlContainer} ${cl.center}`}>
                        <Button buttonType={ButtonType.Primary}
                                type='submit'
                                disabled={form.isSubmitting}
                                isLoading={form.isSubmitting}
                                style={{width: '70%'}}>
                            {t("btn.login")}
                        </Button>
                    </div>
                </form>
            </div>
            <footer className={cl.footer}>
                <span>&copy; {new Date().getFullYear()}</span>
                <span>{t("shared.jb-logistics")} / {t("shared.rasco")}</span>
                <span>{t("shared.labels.rights")}</span>
            </footer>
        </div>
    );
};

export default Login;