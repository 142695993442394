import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IApplicationState} from "../../app/types/redux/IApplicationState";

const initialState: IApplicationState = {
    isHeaderMenuDisabled: false,
    isHeaderMenuShown: true
}

const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        setHeaderMenuDisabledState: (state, action: PayloadAction<boolean>) => {
            state.isHeaderMenuDisabled = action.payload;
        },
        setHeaderMenuShownState: (state, action: PayloadAction<boolean>) => {
            state.isHeaderMenuShown = action.payload;
        },
        setHeaderMenuLabel: (state, action: PayloadAction<string>) => {
            if (!state.isHeaderMenuShown) {
                state.headerMenuLabel = action.payload;
            }
        }
    }
});

export const {setHeaderMenuDisabledState, setHeaderMenuLabel, setHeaderMenuShownState} = applicationSlice.actions;

export default applicationSlice.reducer;