import {ResponseResultCode} from "../types/enums/ResponseResultCode";
import {IApiErrorResourceModel, IApiErrorResourceModelItem} from "./IApiErrorResourceModelItem";
import {loginApiErrors} from "../types/models/ILogin";
import {rascoApplicationApiErrors} from "../types/redux/IRascoApplicationState";
import {orderApiErrors} from "../types/models/IOrder";

type Entity = 'login' | 'user' | 'rascoApplication' | 'order';

const errors: IApiErrorResourceModel = {
    'login': loginApiErrors,
    'rascoApplication': rascoApplicationApiErrors,
    'order': orderApiErrors
};

const getServerErrorResourceName = (entity: Entity, resultCode: ResponseResultCode, field?: string, description?: string): string => {
    if (entity in errors) {
        if (field !== undefined && description !== undefined) {
            let item = errors[entity].find((item: IApiErrorResourceModelItem) => {
                return item.resultCode === resultCode && item.field === field && item.description === description;
            });

            if (item !== null && item !== undefined) {
                return item.resourceName;
            }
        }
        if (field !== undefined) {
            let item = errors[entity].find((item: IApiErrorResourceModelItem) => {
                return item.resultCode === resultCode && item.field === field;
            });

            if (item !== null && item !== undefined) {
                return item.resourceName;
            }
        } else {
            let item = errors[entity].find((item: IApiErrorResourceModelItem) => {
                return item.resultCode === resultCode;
            });

            if (item !== null && item !== undefined) {
                return item.resourceName;
            }
        }
    }

    return '';
}

export default getServerErrorResourceName;